export const updOrderByMonthObj = (key, order, resultObj) => {
    if (resultObj?.[key]) {
        if (resultObj[key][order["manager-name"]]) {
            resultObj[key][order["manager-name"]].push(order);
        } else {
            resultObj[key][order["manager-name"]] = [order];
        }
    } else {
        resultObj[key] = {};
        resultObj[key][order["manager-name"]] = [order];
    }
}

export const updOrderByMonthCalcObj = (key, order, resultObj) => {
    if (resultObj?.[key]) {
        if (resultObj[key][order["manager-name"]]) {
            resultObj[key][order["manager-name"]] += 1;
        } else {
            resultObj[key][order["manager-name"]] = 1;
        }
    } else {
        resultObj[key] = {};
        resultObj[key][order["manager-name"]] = 1;
    }
}