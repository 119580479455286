import React, {useEffect, memo, useMemo} from 'react'
import {useParams, useHistory} from 'react-router-dom'
import B2BLayout from '../../../layouts/b2b'
import '../index.css'
import {useDispatch, useSelector} from 'react-redux'
import {getHrEnums, getHrForm} from '../../../store/hr/action-creators'
import {
    getCandidateId, getHrFormError, getHrFormLoading,
} from './selectors'
import HrTabs from './ui/hr-tabs/hr-tabs'
import {HrForms} from './ui/hr-forms/hr-forms'
import {HStack} from '../../../ui/Stack'
import DefaultJsonFormContext from '../../../components/v2/default-json-form/context/default-json-form-context'
import {
    DefaultJsonFormContextProps
} from '../../../components/v2/default-json-form/context/default-json-form-context-props'

const breadcrumbs = [
    {
        href: '/hr',
        title: 'Учет кандидатов',
    },
]

const AdminNewObjectPage = memo(() => {

    const {id} = useParams<{ id: string }>();
    const history = useHistory();
    const dispatch = useDispatch();
    const candidateId = useSelector(getCandidateId);
    const hrFormIsLoading = useSelector(getHrFormLoading);
    const hrFormError = useSelector(getHrFormError);
    const entityType = 'hr';

    useEffect(() => {
        dispatch(getHrEnums());
        return () => {
        };
    }, [dispatch])

    useEffect(() => {
        if (id === 'new' && candidateId) {
            history.push({
                pathname: `/hr/${candidateId}`,
            })
        }
        return () => {
        };
    }, [id, candidateId])


    useEffect(() => {
        dispatch(getHrForm(id));
        return () => {
        };
    }, [id, dispatch])

    const defaultProps = useMemo(
        (): DefaultJsonFormContextProps => ({
            entityId: id,
            entityType: entityType,
        }),
        [id],
    );


    return (
        <B2BLayout breadcrumbs={breadcrumbs}>
            {
                (hrFormError && !hrFormIsLoading) ? (
                    <HStack gap={"8"} className={'mapWrapper'}>
                        При выполнении запроса возникла ошибка.
                        <br/>
                        {hrFormError}
                        <br/>
                        Попробуйте перезагрузить страницу или открыть карточку повторно.
                    </HStack>
                ) : (
                    <DefaultJsonFormContext.Provider value={defaultProps}>
                        <HrForms id={id}/>
                        <HrTabs id={id}/>
                    </DefaultJsonFormContext.Provider>
                )

            }
        </B2BLayout>
    )
})

export default AdminNewObjectPage;
