import {oneTableRender} from "./oneTableRender";
import {manyTablesData} from "./manyTablesData";

export const calcSalesFunnel = (
    ordersPrepayment,
    ordersMeasurements,
    ordersCO,
    ordersCaseDateFact,
    selectedMonths,
    selectedYears
) => {
    const monthKeys = Object.keys(ordersCO);
    const isOneTableRender = monthKeys.length <= 3;

    return (isOneTableRender
        ? oneTableRender(
            ordersPrepayment,
            ordersMeasurements,
            ordersCO,
            ordersCaseDateFact,
            monthKeys,
            selectedMonths,
            selectedYears,
        ) : manyTablesData(
            ordersPrepayment,
            ordersMeasurements,
            ordersCO,
            ordersCaseDateFact,
            monthKeys,
            selectedMonths,
            selectedYears,
        ))
}