import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Modal, Table } from 'antd'
import fetchApi from '../../../lib/utils/fetch-api'
import { HStack } from '../../ui/Stack'
import { TelegramIcon, WhatsappIcon } from '../../assets/icons'
import { PhoneOutlined } from '@ant-design/icons'
import { modalPhone } from '../input-phone-client/mango-call'

type ContactData = {
  type_id: number
  value: string
  comment: string
}

type ModalProps = {
  visible: boolean
  userId: string
  onClose: () => void
}

const ModalComponent: React.FC<ModalProps> = ({ visible, userId, onClose }) => {
  const [contactData, setContactData] = useState<ContactData[]>([])
  const [enumsData, setEnumsData] = useState<any[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchApi(`/api/customer/get-contacts/${userId}`)
        setEnumsData(response.enums)
        setContactData(response.data)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    if (visible) {
      setLoading(true)
      fetchData().finally(() => {
        setLoading(false)
      })
    }
  }, [visible, userId])


  function formatPhone(phone: string) {
    const match = phone.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/)
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`
    }
  }

  const getEnumValue = useCallback((id: number) => {
    return enumsData.find(item => item.id === id)?.value
  }, [enumsData])

  const onCall = useCallback(phone => {
    modalPhone({ phone })
  }, [])

  const columns = useMemo(() => {
    return [
      {
        title: 'Тип контакта',
        dataIndex: 'type_id',
        key: 'type_id',
        render: (id: number) => getEnumValue(id),
      },
      {
        title: 'Значение',
        dataIndex: 'value',
        key: 'value',
        render: (value: string, item: ContactData) => {
          const phone = item.value
          return (
            <HStack justify='between'>
              {item.type_id === 859 ? formatPhone(value) : value}
              {item.type_id === 859 && (
                <HStack justify='between' gap='4'>
                  <Button
                    type='link'
                    href={`https://t.me/+${phone?.replace(/\D/g, '')}`}
                    target='_blank'
                    style={{ padding: '0', display: 'flex', overflow: 'hidden', border: 'none' }}
                    disabled={!Boolean(phone)}
                    icon={<TelegramIcon disabled={!Boolean(phone)}/>}
                  />
                  <Button
                    type='link'
                    href={`https://wa.me/+${phone?.replace(/\D/g, '')}`}
                    target='_blank'
                    style={{ padding: '0', display: 'flex', overflow: 'hidden', border: 'none' }}
                    disabled={!Boolean(phone)}
                    icon={<WhatsappIcon disabled={!Boolean(phone)}/>}
                  />
                  <Button
                    type='primary'
                    icon={<PhoneOutlined/>}
                    onClick={() => {onCall(phone?.replace(/\D/g, ''))}}
                    disabled={!Boolean(phone)}
                  />
                </HStack>
                )}
            </HStack>
          )
        },
      },
      {
        title: 'Комментарий',
        dataIndex: 'comment',
        key: 'comment',
      },
    ]
  }, [enumsData])

  return (
    <Modal
      title='Дополнительные контакты'
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      <Table
        dataSource={contactData}
        columns={columns}
        loading={loading}
        pagination={false}
      />
    </Modal>
  )
}

export default ModalComponent
