export const monthOrder = [
  {
    value: 'January',
    label: 'Январь'
  },
  {
    value: 'February',
    label: 'Февраль'
  },
  {
    value: 'March',
    label: 'Март'
  },
  {
    value: 'April',
    label: 'Апрель'
  },
  {
    value: 'May',
    label: 'Май'
  },
  {
    value: 'June',
    label: 'Июнь'
  },
  {
    value: 'July',
    label: 'Июль'
  },
  {
    value: 'August',
    label: 'Август'
  },
  {
    value: 'September',
    label: 'Сентябрь'
  },
  {
    value: 'October',
    label: 'Октябрь'
  },
  {
    value: 'November',
    label: 'Ноябрь'
  },
  {
    value: 'December',
    label: 'Декабрь'
  },
]
