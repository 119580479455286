import React, {useState, useEffect} from 'react'
import fetchAPI from 'src/lib/utils/fetch-api'
import {
    IFilterOption,
    IOrdersByManager,
    IOrdersByManagerColumns,
    IOrdersByMonth, IOrdersByMonthColumns,
    OrderItemResponse
} from "../types/types";
import {toCapitalize} from "../../../../utilits/toCapitalize";
import {isValidOrderByManager} from "../lib/isValidOrderByManager";
import dayjs from "dayjs";
import {isValidCOLid} from "../lib/isValidCOLid";
import {useSelector} from "react-redux";
import {createSelector} from 'reselect';
import {formatPrice} from "../../../utils";
import {isValidPrepaymentDate} from "../lib/isValidPrepaymentDate";
import {isValidMeasurements} from "../lib/isValidMeasurements";
import {updOrderByMonthCalcObj, updOrderByMonthObj} from "../lib/updOrderByMonthObj";
import {isDateInRange} from "../lib/isDateInRange";
import {isValidLid} from "../lib/isValidLid";
import {calcSalesFunnel} from "../selectors/calcSalesFunnel";


const createSession = createSelector(
    (state: any) => state.session,
    sessionData => sessionData,
)


export const useFetchData = (
    selectedYears: string[],
    selectedMonths: string[],
    selectedOrganizations: string[],
    selectedOffices: string[],
    selectedBrands: string[],
) => {
    const {sessionData} = useSelector(createSession);

    const [years, setYears] = useState<IFilterOption[]>([])
    const [organizations, setOrganizations] = useState<IFilterOption[]>([])
    const [offices, setOffices] = useState<IFilterOption[]>([])
    const [brands, setBrands] = useState<IFilterOption[]>([])

    const [ordersByManager, setOrdersByManager] = useState<IOrdersByManager>()
    const [ordersByManagerColumns, setOrdersByManagerColumns] = useState<IOrdersByManagerColumns>([])

    const [ordersByMonth, setOrdersByMonth] = useState<IOrdersByMonth>()
    const [ordersByMonthColumns, setOrdersByMonthColumns] = useState<IOrdersByMonthColumns>([])

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [initialLoadComplete, setInitialLoadComplete] = useState<boolean>(false)


    useEffect(() => {
        loadFilterData()
    }, [])

    useEffect(() => {
        if (initialLoadComplete) {
            loadData()
        }
    }, [
        selectedYears,
        selectedMonths,
        selectedOrganizations,
        selectedOffices,
        selectedBrands,
        initialLoadComplete,
    ])

    const loadFilterData = async () => {
        setIsLoading(true)
        try {
            const result = await fetchAPI('/api/order_report/api/filters', {
                headers: {
                    Authorization: 'Basic ' + btoa('zeokna:1q55CVboYQ'),
                },
            })
            const {years, organizations, offices, brands} = result
            setYears(years)
            setOrganizations(organizations)
            setOffices(offices)
            setBrands(brands)
        } catch (e) {
            console.log(e)
        } finally {
            setInitialLoadComplete(true)
        }
    }

    const loadData = async () => {
        setIsLoading(true)
        const filters: any = {
            years: selectedYears.join(','),
            months: selectedMonths.join(','),
            organizations: selectedOrganizations.join(','),
            offices: selectedOffices.join(','),
            brands: selectedBrands.join(','),
        }

        const queryString = new URLSearchParams(filters).toString();

        try {
            let url = `/api/order_report/api?${queryString}`
          //  let url = `http://localhost:3000/api/order_report/api?years=2024&months=%D0%9E%D0%BA%D1%82%D1%8F%D0%B1%D1%80%D1%8C&organizations=&offices=4&brands=1`
            const data = await fetchAPI(url, {
                headers: {
                    Authorization: 'Basic ' + btoa('zeokna:1q55CVboYQ'),
                },
            }) || [];


            const ordersByManagerTmp = {};

            //уникальные ID заказов, у которых Дата заявки находится в пределах выбранного периода, если Причина закрытия заказа не равно Нецелевое обращение)
            const unIdsCO = new Set();
            const ordersCO = {};

            //уникальные ID заказов, у которых Дата заявки находится в пределах выбранного периода
            const unIdsCaseDateFact = new Set();
            const ordersCaseDateFact = {};

            //ЦО/дог - кол-во parent-id, у которых prepayment date находится в пределах выбранного периода
            // const unIdsPrepayment = new Set(); - не сказано про уникальность
            const ordersPrepayment = {};

            //кол-во ID заказов, у которых дата замера находится в пределах выбранного периода и Дата оплаты не null
            //const unIdsMeasurements = new Set();- не сказано про уникальность
            const ordersMeasurements = {};


            data.forEach((item: OrderItemResponse) => {
                if (isValidOrderByManager(item, selectedMonths, selectedYears)) {
                    // @ts-ignore в isValidOrderByManager проверяем на наличие prepayment_date, но изначально может быть null
                    const date = new Date(item['prepayment_date']);
                    const month = date.toLocaleString('ru-RU', {month: 'long', timeZone: 'UTC'});
                    const year = date.getFullYear().toString();
                    const key = `${toCapitalize(month) + year}`;

                    const order = {
                        orderDate: item['prepayment_date'],
                        caseDate: item['case-date-fact'],
                        contractNum: item['production-order-number'],
                        sum: item['contract-sum'],
                        manager: item["manager-name"],
                        days: item['prepayment_date'] ? (dayjs(item['prepayment_date']).diff(item['case-date-fact'], "day") || 1) : 1,
                        key: item['order-date-fact']
                    }

                    if (ordersByManagerTmp?.[key]) {
                        if (ordersByManagerTmp[key][item["manager-name"]]) {
                            ordersByManagerTmp[key][item["manager-name"]].push(order);
                        } else {
                            ordersByManagerTmp[key][item["manager-name"]] = [order];
                        }
                    } else {
                        ordersByManagerTmp[key] = {};
                        ordersByManagerTmp[key][item["manager-name"]] = [order];
                    }
                }

                if (isDateInRange(item?.['case-date-fact'], selectedMonths, selectedYears)) {
                    const date = new Date(item?.['case-date-fact']);
                    const month = date.toLocaleString('ru-RU', {month: 'long', timeZone: 'UTC'});
                    const year = date.getFullYear().toString();
                    const key = `${toCapitalize(month) + year}`;

                    if (isValidPrepaymentDate(item, selectedMonths, selectedYears)) {
                        updOrderByMonthCalcObj(key, item, ordersPrepayment);
                    }

                    if (isValidMeasurements(item, selectedMonths, selectedYears) && item["prepayment_date"]) {
                        updOrderByMonthCalcObj(key, item, ordersMeasurements);
                    }

                    if (isValidCOLid(item, selectedMonths, selectedYears)) {
                        if (!unIdsCO.has(item["parent-id"])) {
                            updOrderByMonthObj(key, item, ordersCO);
                            unIdsCO.add(item["parent-id"]);
                        }
                    }

                    if (isValidLid(item, selectedMonths, selectedYears)) {
                        if (!unIdsCaseDateFact.has(item["parent-id"])) {
                            updOrderByMonthCalcObj(key, item, ordersCaseDateFact);
                            unIdsCaseDateFact.add(item["parent-id"]);
                        }

                    }

                }
            })

            const ordersByMonthTmp = calcSalesFunnel(ordersPrepayment,
                ordersMeasurements,
                ordersCO,
                ordersCaseDateFact,
                selectedMonths,
                selectedYears) as IOrdersByMonth;


            setOrdersByManagerColumns([
                {
                    title: 'Менеджер',
                    dataIndex: 'manager',
                    key: 'manager',
                    sorter: {
                        compare: (a, b) => a.manager.localeCompare(b.manager),
                        multiple: 4,
                    },
                },
                {
                    title: 'Дата оплаты',
                    dataIndex: 'orderDate',
                    key: 'orderDate',
                    sorter: {
                        //@ts-ignore
                        compare: (a, b) => new Date(a.orderDate) - new Date(b.orderDate),
                        multiple: 1,
                    },
                    render: (v) => v ? dayjs(v).format('DD/MM/YY') : 'не установлена',
                },
                {
                    title: 'Дата заявки',
                    dataIndex: 'caseDate',
                    key: 'caseDate',
                    sorter: {
                        //@ts-ignore
                        compare: (a, b) => new Date(a.caseDate) - new Date(b.caseDate),
                        multiple: 2,
                    },
                    render: v => v ? dayjs(v).format('DD/MM/YY') : 'не установлена'
                },
                {
                    title: 'Договор',
                    dataIndex: 'contractNum',
                    key: 'contractNum'
                },
                {
                    title:
                        'Сделка, дни',
                    dataIndex: 'days',
                    key: 'days',
                    sorter: {
                        compare: (a, b) => a.days - b.days,
                        multiple: 3,
                    },
                },
                {
                    title: 'Продажи',
                    dataIndex: 'sum',
                    key: 'sum',
                    sorter: {
                        compare: (a, b) => a.sum - b.sum,
                        multiple: 5,
                    },
                    render: (v) => formatPrice(v)
                },
            ]);
            setOrdersByMonthColumns([
                {
                    title: 'Менеджер',
                    dataIndex: 'manager',
                    key: 'manager',
                    sorter: {
                        compare: (a, b) => a.manager?.localeCompare?.(b.manager),
                        multiple: 2,
                    },
                },
                (sessionData.roles.includes(16) && {
                    title: 'ЦО',
                    dataIndex: 'co',
                    key: 'co',
                    sorter: {
                        compare: (a, b) => a.co - b.co,
                        multiple: 1,
                    },
                }),
                {
                    title: 'Отказы до КП',
                    dataIndex: 'cancel_before_kp',
                    key: 'cancel_before_kp',
                    sorter: {
                        compare: (a, b) => a.cancel_before_kp - b.cancel_before_kp,
                        multiple: 3,
                    },
                },
                {
                    title: 'Отказы после КП',
                    dataIndex: 'cancel_after_kp',
                    key: 'cancel_after_kp',
                    sorter: {
                        compare: (a, b) => a.cancel_after_kp - b.cancel_after_kp,
                        multiple: 4,
                    },
                },
                {
                    title: 'Заказы в работе',
                    dataIndex: 'workOrders',
                    key: 'workOrders',
                    sorter: {
                        compare: (a, b) => a.workOrders - b.workOrders,
                        multiple: 5,
                    },
                },
                {
                    title: 'Замеров выполнено',
                    dataIndex: 'measurements',
                    key: 'measurements',
                    sorter: {
                        compare: (a, b) => a.measurements - b.measurements,
                        multiple: 6,
                    },
                },
                {
                    title: 'Договоры по заявкам этого месяца',
                    dataIndex: 'contracts',
                    key: 'contracts',
                    sorter: {
                        compare: (a, b) => a.contracts - b.contracts,
                        multiple: 9,
                    },
                },
                {
                    title: '% лид/дог',
                    dataIndex: 'percent_ld',
                    key: 'percent_ld',
                    sorter: {
                        compare: (a, b) => a.percent_ld - b.percent_ld,
                        multiple: 7,
                    },
                    render: (v) => typeof v === "number" ? v?.toFixed(2) + '%' : v
                },
                {
                    title: '% зам/дог',
                    dataIndex: 'percent_md',
                    key: 'percent_md',
                    sorter: {
                        compare: (a, b) => a.percent_md - b.percent_md,
                        multiple: 8,
                    },
                    render: (v) => typeof v === "number" ? v?.toFixed(2) + '%' : v
                },
                {
                    title: '% ЦО/дог',
                    dataIndex: 'percent_cc',
                    key: 'percent_cc',
                    sorter: {
                        compare: (a, b) => a.percent_cc - b.percent_cc,
                        multiple: 10,
                    },
                    render: (v) => typeof v === "number" ? v?.toFixed(2) + '%' : v
                },
                {
                    title: 'СЧ продаж',
                    dataIndex: 'average_score',
                    key: 'average_score',
                    sorter: {
                        compare: (a, b) => a.average_score - b.average_score,
                        multiple: 11,
                    },
                    render: (v) => v ? formatPrice(v) : ''
                },
            ]);
            setOrdersByManager(ordersByManagerTmp);
            setOrdersByMonth(ordersByMonthTmp);
        } catch (e) {
            console.log(e)
        } finally {
            setIsLoading(false)
        }
    }


    const refetchData = () => {
        loadData()
    }

    return {
        ordersByManager,
        ordersByManagerColumns,
        ordersByMonth,
        ordersByMonthColumns,
        years,
        organizations,
        offices,
        brands,
        isLoading,
        refetchData,
    }
}
//@ts-ignore