// 1.ЦО (количество уникальных ID заказов, у которых Дата заявки находится в пределах выбранного периода,
//     если Причина закрытия заказа не равно Нецелевое обращение) case-date-fact
//cancel-title не равно ТЕСТ или ДУБЛЬ или client-name не содержит "тест".

import {isDateInRange} from "./isDateInRange";

export const isValidCOLid = (order, selectedMonths, selectedYears): boolean => {
    const cancelTitle = order["cancel-title"]?.toUpperCase();
    const clientName = order["client-name"]?.toUpperCase();

    return cancelTitle !== 'НЕЦЕЛЕВОЕ ОБРАЩЕНИЕ'
        && cancelTitle !== 'ТЕСТ'
        && cancelTitle !== 'ДУБЛЬ'
        && !clientName?.match(/(ТЕСТ)/g)
        && order["case-date-fact"]
        && isDateInRange(order["case-date-fact"], selectedMonths, selectedYears);
}