import React from 'react'
import { Select } from 'antd'
import { IMonthFilterProps, IFilterOption } from '../interfaces/interfaces'

const { Option } = Select

const MonthFilter: React.FC<IMonthFilterProps> = ({
  selectedMonths,
  onMonthChange,
  months,
  style,
}) => {

  return (
    <Select
      mode='multiple'
      value={selectedMonths}
      onChange={onMonthChange}
      style={style}
      placeholder='Все месяцы'
      allowClear
    >
      {months.map(month => (
        <Option key={month.value} value={month.value}>
          {month.label}
        </Option>
      ))}
    </Select>
  )
}

export default MonthFilter
