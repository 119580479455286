import React, {memo, useEffect, useState} from "react";
import {HStack} from "../../../../ui/Stack";
import {Button} from "antd";
import {useHistory} from "react-router-dom";


interface IStage {
    id: number,
    name: string,
    code: string,
    style: any
}

interface IButton {
    name: string,
    code: string,
    isActive: boolean
}


interface IFilterButtons {
    groupName?: string,
    stages?: IStage[],
    onChange: (filters: any, isButtonChange?: boolean) => void
}

//иск выполнено отменено
const excludeButtonStages = ['done', 'cancelled'];
export const FilterStageButtons = memo((props: IFilterButtons) => {
    const {
        groupName = 'stage',
        stages,
        onChange,
    } = props;
    const [buttons, setButtons] = useState<IButton[]>([]);
    const history = useHistory();
    const [isUpd, setIsUpd] = useState(false);

    useEffect(() => {
        if (!stages) {
            return;
        }
        let urlSearchParams = new URLSearchParams(history.location.search);

        const currentStages = urlSearchParams.get(groupName);
        const currentStagesArr = currentStages?.split(',') || [];

        const buttons = stages.filter((stage) => !excludeButtonStages.includes(stage.code));
        const inWorkStages = buttons.map((stage) => stage.code);

        const result: IButton[] = buttons.map((stage) => ({
            name: stage.name,
            code: stage.code,
            isActive: currentStagesArr.includes(stage.code)
        }));

        result.push({
            name: 'В работе',
            code: 'all',
            isActive: inWorkStages.every((stage) => currentStagesArr.includes(stage)),
        })

        setButtons(result);
    }, [stages, groupName, history.location]);

    useEffect(() => {
        if (!isUpd) {
            return;
        }
        let urlSearchParams = new URLSearchParams(history.location.search);
        const currentUrlParams = (urlSearchParams.get(groupName)?.split(',') || [])
            .filter((currentUrlParams) => excludeButtonStages.includes(currentUrlParams));

        let tmp = new Set([
            ...currentUrlParams,
            ...buttons.filter((button) => button.isActive && button.code !== 'all').map((button) => button.code)
        ]);

        let resultButtons = Array.from(tmp);
        if (resultButtons?.length === 0 || (resultButtons?.length === 1 && resultButtons[0] === '')) {
            urlSearchParams.delete(groupName);
            resultButtons = []
        }
        onChange({...Object.fromEntries(urlSearchParams), [groupName]: resultButtons}, true);
        setIsUpd(false);
    }, [buttons, groupName, history.location])

    const onClickButton = (code: string) => () => {
        setButtons((prevState) => {
            if (code !== 'all') {
                return [
                    ...(prevState.map((prevButton) => {
                        if (prevButton.code === code) {
                            prevButton.isActive = !prevButton.isActive;
                        }
                        return prevButton
                    }))
                ]
            }

            const currentAll = prevState.find((btn) => btn.code === 'all');
            return [
                ...(prevState.map((prevButton) => {
                    prevButton.isActive = !currentAll!.isActive
                    return prevButton
                }))
            ]
        })
        setIsUpd(true)
    }

    return (
        <HStack gap={"16"} justify={"start"} max style={{marginBottom: '7px'}}>
            {
                buttons?.map((button) => {
                    return <Button
                        key={button.name}
                        type={button.isActive ? 'primary' : 'default'}
                        onClick={onClickButton(button.code)}
                    >
                        {button.name}
                    </Button>
                })
            }
        </HStack>
    )
})
