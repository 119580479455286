import {isDateInRange} from "./isDateInRange";

export const isValidForPercentLD = (order, selectedMonths: string[], selectedYears: string[]): boolean => {

    return Boolean(order["prepayment_date"])
        && isDateInRange(order["prepayment_date"], selectedMonths, selectedYears);
}

// 7. % лид/дог = кол-во ЦО, если дата оплаты находится в пределах выбранного периода делим на кол-во уникальных ID заказов,
//     у которых Дата заявки находится в пределах выбранного периода)
