import fetchAPI from "../../../../../lib/utils/fetch-api";

export const getCall = async (id: string | number) => {
    try {
        const response = await fetchAPI(
            `/api/v2/mango-record/${id}`
        )
        if (response['errors']) {
            let error = response['errors'];
            if (Array.isArray(error)) {
                error = error.join();
            }
            throw new Error(error);
        }

        return response;
    } catch (err) {
        console.log(err);
        return false;
    }
}
