import {isDateInRange} from "./isDateInRange";

export const isValidLid = (order, selectedMonths: string[], selectedYears: string[]): boolean => {
    const cancelTitle = order["cancel-title"]?.toUpperCase();
    const clientName = order["client-name"]?.toUpperCase();

    return Boolean(order["case-date-fact"])
        && cancelTitle !== 'ТЕСТ'
        && cancelTitle !== 'ДУБЛЬ'
        && !clientName?.match(/(ТЕСТ)/g)
        && isDateInRange(order["case-date-fact"], selectedMonths, selectedYears);
}

// на кол-во уникальных ID заказов,
//     у которых Дата заявки находится в пределах выбранного периода