import { Avatar, Comment } from 'antd'
import React, { ReactNode, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { MessageContainer } from './styles'
import { IAvitoChat } from '../../model/types/types'

dayjs.extend(localizedFormat)
dayjs().format('L LT')

interface IDataChat {
    data: IAvitoChat
    managerTitle?: string
    managerAvatar?: ReactNode
    userTitle?: string
    userAvatar?: ReactNode
    className?: string
}

export const MessageBox = (props: IDataChat) => {
    const {
        data,
        className,
        managerTitle = 'Менеджер',
        managerAvatar = 'M',
        userTitle = 'Кандидат',
        userAvatar = 'К'
    } = props

    const getDate = (date: string) => {
        return dayjs(date).format('D MMMM YYYY в HH:mm:ss')
    }

    if (!data) {
        return null
    }

    const messageContainerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        messageContainerRef.current?.scrollTo({
            top: messageContainerRef.current.scrollHeight,
            behavior: 'smooth'
        })
    }, [data.chat.length])

    return (
      <MessageContainer className={className} ref={messageContainerRef}>
          {data?.chat?.map((item) => {
              if (item.direction === 'out') {
                  return <Comment
                    author={managerTitle}
                    avatar={<Avatar>{managerAvatar}</Avatar>}
                    content={item.msg}
                    datetime={getDate(item.created_at)}
                    key={item.created_at}
                  />
              }
              if (item.direction === 'in') {
                  return <Comment
                    key={item.created_at}
                    className='incoming'
                    author={userTitle}
                    avatar={<Avatar>{userAvatar}</Avatar>}
                    content={item.msg}
                    datetime={getDate(item.created_at)}
                  />
              }
          })}
      </MessageContainer>
    )
}
