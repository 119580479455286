import type { ColumnsType } from 'antd/es/table'
import React, { useCallback } from 'react'
import { DataType } from './interface'
import { Popconfirm } from 'antd'
import { ButtonActionWrapper } from '../../../components/list-operations/styles'
import { DeleteTwoTone } from '@ant-design/icons'
import { Value } from '../../elements'

const formatPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return <Value data={undefined}/>
  }
  const cleaned = ('' + phoneNumber).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/)
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`
  }
  return phoneNumber
}

const Columns: ColumnsType<DataType> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 70,
    fixed: 'left'
  },
  {
    title: 'ФИО',
    dataIndex: 'name',
    key: 'name',
    width: 170,
  },
  {
    title: 'Телефон',
    width: 120,
    dataIndex: 'phone',
    key: 'phone',
    render: val => formatPhoneNumber(val)
  },
  {
    title: 'E-mail',
    width: 120,
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Авито ID',
    width: 120,
    dataIndex: 'avito_id',
    key: 'avito_id',
  },
]

const ColumnAction: ColumnsType<DataType> = [
  {
    title: 'Действие',
    key: 'operation',
    className: 'no-edit',
    fixed: 'right',
    width: 100,
    align: 'center',
    render: (_, {id, deleteRecord}) => {
      return (
        <span onClick={e => {
          e.stopPropagation()
        }}>
          <DeleteButton id={id} deleteRecord={deleteRecord}/>
        </span>
      )
    }
  }
]

export function DeleteButton({id, deleteRecord}) {
  const localDeleteRecord = useCallback(() => deleteRecord(id), [deleteRecord, id])
  return (
    <Popconfirm
      title='Вы уверены?'
      okText='Да'
      cancelText='Нет'
      onConfirm={() => localDeleteRecord()}
    >
      <ButtonActionWrapper title='Удалить'>
        <DeleteTwoTone />
      </ButtonActionWrapper>
    </Popconfirm>
  )
}

export const getColumns = (leader: Boolean, sorter) => {
  // собирает колонки для разных ролей
  const columns = leader ? Columns.concat(ColumnAction) : Columns
  // Формирует колонки и устанавливает порядок сортировки
  return columns.map(item => {
    if (item.key === sorter.sort_field)
      item.sortOrder = sorter.sort_order
    else
      delete item.sortOrder
    return item
  })
}

