import {isValidCancelBKP} from "../lib/isValidCancelBKP";
import {isValidCancelAKP} from "../lib/isValidCancelAKP";
import {isValidWorkOrder} from "../lib/isValidWorkOrder";
import {isValidMeasurements} from "../lib/isValidMeasurements";
import {isValidContracts} from "../lib/isValidContracts";
import {isValidForPercentLD} from "../lib/isValidForPercentLD";
import {isValidPrepaymentDate} from "../lib/isValidPrepaymentDate";

export const calcOrders = (
    orders,
    ordersByPrepaymentCount: number,
    ordersByMeasurementsCount: number,
    ordersByCaseDateFactCount: number,
    selectedMonths: string[],
    selectedYears: string[],
    manager: string
) => {
    let co: number = orders.length,
        cancel_before_kp: number = orders.filter((i) => isValidCancelBKP(i)).length,
        cancel_after_kp: number = orders.filter((i) => isValidCancelAKP(i)).length,
        workOrders: number = orders.filter((i) => isValidWorkOrder(i)).length,
        measurements: number = orders.filter((i) => isValidMeasurements(i, selectedMonths, selectedYears)).length,
        contracts: number = orders.filter((i) => isValidContracts(i, selectedMonths, selectedYears)).length,
        _percent_ldCount: number = orders.filter((i) => isValidForPercentLD(i, selectedMonths, selectedYears)).length,
        percent_ld: number = (_percent_ldCount / ordersByCaseDateFactCount) * 100,
        percent_md: number = (ordersByMeasurementsCount / measurements) * 100,
        percent_cc: number = (ordersByPrepaymentCount / co) * 100,
        _sum: number = orders.filter(
            (i) => isValidPrepaymentDate(i, selectedMonths, selectedYears)
        ).reduce((acc, i) => acc + i['products-price'], 0),
        average_score: number = ( _sum / ordersByPrepaymentCount) || 0

    if (!Number.isFinite(percent_md)) {
        percent_md = 0
    }
    if (!Number.isFinite(percent_cc)) {
        percent_cc = 0
    }
    if (!Number.isFinite(percent_ld)) {
        percent_ld = 0
    }

    return {
        manager: manager,
        cancel_before_kp,
        co,
        cancel_after_kp,
        workOrders,
        measurements,
        contracts,
        percent_ld,
        percent_md,
        percent_cc,
        average_score,
        _sum: _sum,
        _percent_ldCount: _percent_ldCount,
        _prepaymentCount: ordersByPrepaymentCount,
        _measurementsCount: ordersByMeasurementsCount,
        _caseDateFactCount: ordersByCaseDateFactCount,
    }
}