import {Alert} from "antd";
import React from "react";

export const schema = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
      title: 'Название'
    },
    type_id: {
      type: 'string',
      title: 'Тип организации',
      view: {
        name: 'select-from-enum'
      },
      options: {}
    },
    brand_id: {
      type: 'string',
      title: 'Бренд',
      allowClear: true,
      view: {
        name: 'select-from-enum'
      },
      options: {}
    },
    task_responsible_roles: {
      type: 'string',
      title: 'Ответственные роли по задачам',
      allowClear: true,
      view: {
        name: 'select-from-enum'
      },
      options: {
        multiple: true
      }
    },
    devider_avito: {
      type: 'devider',
      style: { gridColumn: '1 / -1' },
      content: (
        <Alert
          message='Интеграция АВИТО'
          description={(<div>
            В <a href='https://www.avito.ru/profile' target='_blank'>Личном кабинете</a> указан <b>номер профиля</b>.<br />
            На странице <a href='https://www.avito.ru/professionals/api' target='_blank'>Интеграции</a> указаны <b>client_id</b> и <b>client_secret</b>.<br />
            Для настройки интеграции с авито скопируйте их и вставте в поля, ниже.
          </div>)}
          type='info'
        />
      )},
    avito_account_id: {
      type: 'string',
      title: 'Авито: номер профиля'
    },
    avito_client_id: {
      type: 'string',
      title: 'Авито: client_id'
    },
    avito_client_secret: {
      type: 'string',
      title: 'Авито: client_secret'
    },
  }
}