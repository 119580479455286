import React, { useCallback, useState } from 'react'
import { HStack } from '../../ui/Stack'
import { FileButton } from '../input-phone-client/styles'
import { DashOutlined, PhoneOutlined } from '@ant-design/icons'
import { Button, Input } from 'antd'
import { TelegramIcon, WhatsappIcon } from '../../assets/icons'
import { modalPhone } from '../input-phone-client/mango-call'
import ModalComponent from './modal'

function formatPhone(phone: string) {
  const match = phone.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/)
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`
  }
}

function InputAdditionalContacts({ value }) {
  const customer_id = value?.customer_id
  const [visibleModal, setVisibleModal] = useState(false)
  const contact = value?.data?.value
  const formattedPhone = contact && formatPhone(contact)

  const formatValue = useCallback(() => {
    if(!value?.data || !contact) {
      return ''
    }
    let formattedValue = `${formattedPhone}`
    if (value?.data?.comment) {
      formattedValue += ` - ${value?.data.comment}`
    }
    return formattedValue
  }, [value, contact])

  const formattedValue = formatValue()

  const handleOpenModal = useCallback(() => {
    setVisibleModal(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    setVisibleModal(false)
  }, [])

  const onCall = useCallback(phone => {
    modalPhone({ phone })
  }, [])

  return (
    <div style={{ display: 'flex', width: 'max-content' }} className='input_phone_number'>
      <FileButton disabled={false} onClick={handleOpenModal} className='ant-input'>
        <DashOutlined/>
      </FileButton>
      <Input value={formattedValue} disabled={true} style={{ flex: 1 }}/>
      <HStack>
        <Button
          type='link'
          href={`https://t.me/+${contact?.replace(/\D/g, '')}`}
          target='_blank'
          style={{ padding: '0', display: 'flex', overflow: 'hidden', border: 'none' }}
          disabled={!Boolean(contact)}
          icon={<TelegramIcon disabled={!Boolean(contact)}/>}
        />
        <Button
          type='link'
          href={`https://wa.me/+${contact?.replace(/\D/g, '')}`}
          target='_blank'
          style={{ padding: '0', display: 'flex', overflow: 'hidden', border: 'none' }}
          disabled={!Boolean(contact)}
          icon={<WhatsappIcon disabled={!Boolean(contact)}/>}
        />
        <Button
          type='primary'
          icon={<PhoneOutlined/>}
          onClick={() => { onCall(contact?.replace(/\D/g, '')) }}
          disabled={!Boolean(contact)}
        />
      </HStack>
      <ModalComponent visible={visibleModal} userId={customer_id} onClose={handleCloseModal} />
    </div>
  )
}

export default InputAdditionalContacts
