import {calcOrders} from "./calcOrders";
import {formatPrice} from "../../../utils";
import {initialTotalByMonth} from "../consts/totalByMonth";

export const manyTablesData = (
    ordersPrepayment,
    ordersMeasurements,
    ordersCO,
    ordersCaseDateFact,
    monthKeys:string[],
    selectedMonths:string[],
    selectedYears:string[]
) => {
    const ordersByMonthResult = {}

    monthKeys.forEach((month: string) => {
        const managers = Object.keys(ordersCO[month]);
        const total = {...initialTotalByMonth}

        ordersByMonthResult[month] = managers.map((manager) => {

            const orders = ordersCO[month][manager];

            const ordersByPrepaymentCount = ordersPrepayment?.[month]?.[manager] || 0;
            const ordersByMeasurementsCount = ordersMeasurements?.[month]?.[manager] || 0;
            const ordersByCaseDateFactCount = ordersCaseDateFact?.[month]?.[manager] || 0;

            const calc = calcOrders(
                orders,
                ordersByPrepaymentCount,
                ordersByMeasurementsCount,
                ordersByCaseDateFactCount,
                selectedMonths,
                selectedYears,
                manager
            );


            total.total_co += calc.co;
            total.total_cancel_before_kp += calc.cancel_before_kp;
            total.total_cancel_after_kp += calc.cancel_after_kp;
            total.total_workOrders += calc.workOrders;
            total.total_measurements += calc.measurements;
            total.total_contracts += calc.contracts;
            total._percent_ldCount += calc._percent_ldCount;
            total._sum += calc._sum;
            total._caseDateFactCount += calc._caseDateFactCount;
            total._measurementsCount += calc._measurementsCount;
            total._prepaymentCount += calc._prepaymentCount;

            return calc;
        })

        // Данные колонки не используются в сортировке, отображаются в summary
        // только здесь приводятся к строке, до этого только number

        let total_percent_ld = (total._percent_ldCount /  total._caseDateFactCount) * 100;
        if (!Number.isFinite(total_percent_ld)) {
            total_percent_ld = 0
        }

        let total_percent_md = (total._measurementsCount / total.total_measurements) * 100;
        if (!Number.isFinite(total_percent_md)) {
            total_percent_md = 0
        }

        let total_percent_cc = (total._prepaymentCount / total.total_co) * 100;
        if (!Number.isFinite(total_percent_cc)) {
            total_percent_cc = 0
        }


        //@ts-ignore
        total.total_percent_ld = total_percent_ld?.toFixed(2) + '%'
        //@ts-ignore
        total.total_percent_md = total_percent_md?.toFixed(2) + '%'
        //@ts-ignore
        total.total_percent_cc = total_percent_cc?.toFixed(2) + '%'
        //@ts-ignore
        total.total_average_score = formatPrice(total._sum / total._prepaymentCount)

        ordersByMonthResult[month].push(total)
    })
    return ordersByMonthResult;
}