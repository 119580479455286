import React, {memo, useCallback, useEffect} from 'react'
import {Menu, Badge, Skeleton} from 'antd'
import {HStack} from '../../../../../ui/Stack'
import './index.css'
import {useDispatch, useSelector} from 'react-redux'
import {getChatAvito} from '../../../../../store/chat_avito/action-creators'
import {
  getActiveAvitoChat,
  getActiveAvitoChatId,
  selectChatAvitoData,
  selectChatAvitoError,
  selectChatAvitoIsLoading,
} from '../../selectors'
import {ReplyBox} from './reply-box'
import {ChatBadgeBox, ChatBox} from './styles'
import {IChats} from './types'
import {useEventSource} from '../../../../../hooks/useEventSource/useEventSource'
import {addChatAvitoData, setActiveAvitoChatId} from '../../../../../store/chat_avito/actions'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {MessageBox} from "../../../../../ui/MessageBox";

dayjs.extend(utc)

export const Chats: React.FC<IChats> = memo((props: IChats) => {
  const {id} = props
  const dispatch = useDispatch()
  const chats = useSelector(selectChatAvitoData)
  const isLoading = useSelector(selectChatAvitoIsLoading)
  const error = useSelector(selectChatAvitoError)
  const activeChatId = useSelector(getActiveAvitoChatId)
  const activeChatData = useSelector(getActiveAvitoChat)
  const {sse, subscribe} = useEventSource()


  useEffect(() => {
    let mounted = true
    if (mounted) {
      dispatch(getChatAvito(id, 'case'))
      subscribe(`/api/avito/live/chat/case/${id}`, data => {
        dispatch(addChatAvitoData(data))
      })
    }
    return () => {
      mounted = false
      sse?.current?.close()
    }
  }, [id, dispatch])

  const changeChatHandler = useCallback((chat): void => {
    if (chat.key === activeChatId) {
      return
    }
    dispatch(setActiveAvitoChatId(chat.key))
  }, [activeChatId, dispatch])


  if (isLoading) {
    return (
      <HStack gap={'8'} className={'mapWrapper'}>
        <Skeleton active={true}/>
      </HStack>
    )
  }

  if (error && !isLoading && !chats) {
    return (
      <HStack gap={"8"} className={'mapWrapper'}>
        При выполнении запроса возникла ошибка.
        Попробуйте перезагрузить страницу или открыть карточку повторно.
      </HStack>
    )
  }

  if (!chats?.length) {
    return (
      <HStack gap={"8"} className={'mapWrapper'}>
        Нет активных чатов
      </HStack>
    )
  }

  return (
    <HStack align='stretch'>
      <Menu
        defaultSelectedKeys={activeChatId}
        onClick={changeChatHandler}
      >
        {chats && chats.map(item =>
          <Menu.Item key={item?.chat_id}>
            {item.name}
            <ChatBadgeBox>
              <Badge count={item.count_not_read} offset={[10, 0]}/>
            </ChatBadgeBox>
          </Menu.Item>
        )}
      </Menu>
      <ChatBox>
        <MessageBox data={activeChatData} userAvatar={'З'} userTitle={'Заказчик'}/>
        <ReplyBox id={id} chat_id={activeChatId}/>
      </ChatBox>
    </HStack>
  )
})
