import React, { useCallback, useEffect, useState } from 'react'
import { Button, Table } from 'antd'
import fetchAPI from '../../../../../lib/utils/fetch-api'
import dayjs from 'dayjs'
import { getCall } from '../../../../components/v2/activity-timeline/api/getCall'
import { formatPhoneNumber } from '../../../orders/list/columns'

function secondsToHumanReadable(seconds: number) {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  return dayjs().set('minute', minutes).set('second', remainingSeconds).format('m:ss')
}

const columns = [
  {
    title: 'Дата',
    dataIndex: 'date',
    key: 'date',
    render: (date: string) => dayjs(date).format('DD.MM.YYYY HH:mm:ss'),
  },
  {
    title: 'Телефон',
    dataIndex: 'phone',
    key: 'phone',
    render: (value?: string) => formatPhoneNumber(value),
  },
  {
    title: 'Продолжительность',
    dataIndex: 'duration',
    key: 'duration',
    render: (duration: number) => secondsToHumanReadable(duration),
  },
  {
    title: 'Офис',
    dataIndex: 'office',
    key: 'office',
  },
  {
    title: 'Менеджер',
    dataIndex: 'manger',
    key: 'manger',
  },
  {
    title: 'Статус',
    dataIndex: 'call_direction',
    key: 'call_direction',
    render: (direction: string, row: ICallLogData) => `${direction}, ${row.state}`,
  },
  {
    title: 'Запись',
    dataIndex: 'records',
    key: 'records',
    render: (records: null | string[], row: ICallLogData) => {
      if(records && records.length) {
        return (<LoadButtonAndPlayer rowId={row.id} />)
      }
      return null
    },
  },
]

interface ICallLogData {
  id: number
  date: string
  duration: number
  call_direction: string
  record: string[]
  manager: string
  office: string
  state: string
}

function TabCallLog({ clientId }){
  const [data, setData] = useState<ICallLogData[]>([])

  useEffect(() => {
    (async () => {
      const result = await fetchAPI(`/api/mango-log?customer=${clientId}`)
      setData(result as ICallLogData[])
    })()
  }, [clientId])

  return <Table dataSource={data} columns={columns} pagination={false} key='id'/>
}
function LoadButtonAndPlayer({ rowId }) {
  const [calls, setCalls] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const handleLoadCall = useCallback(() => {
    const id = rowId
    if (!id) {
      return
    }
    setIsLoading(true)
    getCall(id).then((response) => {
      if (response && response?.length) {
        setCalls(response as string[])
      }
    }).finally(() => setIsLoading(false))
  }, [])

  return (
    <div style={{ minWidth: 200, display: 'flex', justifyContent: 'center' }}>
      {
        !isLoading && calls.length > 0 ? (
          <div style={{ width: '100%', display: 'flex', margin: '1px 0' }}>
            {
              calls.map((call, index) => (
                <audio key={index} controls style={{ width: '100%', height: '30px' }}>
                  <source src={call} type='audio/mpeg' />
                </audio>
              ))
            }
          </div>
        ) : (
          <Button type={'default'} onClick={handleLoadCall} loading={isLoading}>
            Загрузить звонок
          </Button>
        )
      }
    </div>
  )
}

export default TabCallLog
