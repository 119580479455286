import styled from 'styled-components';

interface IconWrapperStyled {
  disabled?: boolean;
}

const IconWrapper = styled.div<IconWrapperStyled>`
  display: flex;
  border: ${props => props.disabled ? '1px solid #CACACA' : undefined};
`;

export default IconWrapper;
