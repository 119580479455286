import React from 'react'
import {Button, Space, Upload} from 'antd'
import {PlusOutlined, UploadOutlined} from '@ant-design/icons'
import {UploadProps} from 'antd/es/upload'

interface ActionButtonsProps {
  onCreate: () => void
  uploadProps: UploadProps
  onSendToSchedex: () => void
  onUnloadFromSchedex: () => void
  isSendToSchedexEnabled: boolean
  isUnloadFromSchedexEnabled: boolean
  onSetVehicle: () => void
  isSetVehicleEnabled: boolean
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
                                                       onCreate,
                                                       uploadProps,
                                                       onSendToSchedex,
                                                       onUnloadFromSchedex,
                                                       onSetVehicle,
                                                       isSendToSchedexEnabled,
                                                       isUnloadFromSchedexEnabled,
                                                       isSetVehicleEnabled,
                                                     }) => (
  <Space style={{marginBottom: '20px', width: '100%'}}>
    <Button onClick={onCreate} icon={<PlusOutlined/>}>
      Создать
    </Button>
    <Upload {...uploadProps}>
      <Button icon={<UploadOutlined/>}>Загрузить CSV</Button>
    </Upload>
    <Button
      type="primary"
      disabled={!isSendToSchedexEnabled}
      onClick={onSendToSchedex}
    >
      Отправить в Шедекс
    </Button>
    <Button
      type="primary"
      disabled={!isUnloadFromSchedexEnabled}
      onClick={onUnloadFromSchedex}
    >
      Выгрузить из Шедекс
    </Button>
    <Button
      type="primary"
      disabled={!isSetVehicleEnabled}
      onClick={onSetVehicle}
    >
      Назначить экипаж
    </Button>
  </Space>
);

export default React.memo(ActionButtons)
