import React, { useCallback, useMemo } from 'react'
import { IPhone } from './interface'
import { DashOutlined, PhoneOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { FileButton } from './styles'
import fetchAPI from 'src/lib/utils/fetch-api'
import { modalPhone } from './mango-call'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ru from 'react-phone-input-2/lang/ru.json'
import { TelegramIcon, WhatsappIcon } from '../../assets/icons'
import { HStack } from '../../ui/Stack'

function parsePhone(phone: any) {
  if (typeof phone === 'string') {
    return phone.replace(/[^\d\+]/g, '')
  }
  return null
}

function InputPhone({value, onChange, UISchema}: IPhone) {
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  const areaCodes = ['310', '311', '312', '313', '315', '318', '321', '324', '325', '326', '327', '336', '7172', '73622']
  const areaCodesWithBrackets = areaCodes.map((code) => `(${code})`)
  const hideDashes = useMemo(() => Boolean(UISchema?.options?.hideDashes), [UISchema])
  const customer_id = useMemo(() => UISchema?.options?.customer_id, [UISchema])

  const handleChange = ({phone}) => {
    onChange?.(`+${phone}`)
  }

  const onCall = useCallback(() => {
    modalPhone({ phone: value })
  }, [value])

  const onClient = useCallback(async () => {
    Object.assign(window.location, { href: `/client-new/${customer_id}` })
  }, [customer_id])

  return (
    <div style={{ display: 'flex', width: 'max-content' }} className='input_phone_number'>
      {!hideDashes && customer_id &&
        <FileButton disabled={false} onClick={onClient} className='ant-input'><DashOutlined/></FileButton>
      }
      <PhoneInput
        enableSearch={true}
        searchPlaceholder={'Поиск'}
        localization={ru}
        country={'ru'}
        onlyCountries={['ru', 'kz', 'by', 'tj', 'uz', 'am', 'kg', 'az', 'md', 'tm']}
        value={value}
        disabled={disabled}
        containerStyle={{ width: 'auto' }}
        inputStyle={{ borderRadius: '2px', height: '32px' }}
        areaCodes={areaCodesWithBrackets}
        masks={{ kz: '(...) ..-..-..' }}
        isValid={(number, country) => {
          if (number.length < 3)
            return true
          const regex = /\./g;
          const format = country?.['format']
          if (format) {
            const length = (format.match(regex) || []).length;
            if (number.length === length) {
              handleChange({phone: number})
              return true
            }
          }
          return false
        }}
      />
      <HStack>
        <Button
          type='link'
          href={`https://t.me/+${value?.replace(/\D/g, '')}`}
          target='_blank'
          style={{ padding: '0', display: 'flex', overflow: 'hidden', border: 'none' }}
          disabled={!Boolean(value)}
          icon={<TelegramIcon disabled={!Boolean(value)}/>}
        />
        <Button
          type='link'
          href={`https://wa.me/+${value?.replace(/\D/g, '')}`}
          target='_blank'
          style={{ padding: '0', display: 'flex', overflow: 'hidden', border: 'none' }}
          disabled={!Boolean(value)}
          icon={<WhatsappIcon disabled={!Boolean(value)}/>}
        />
        <Button
          type='primary'
          icon={<PhoneOutlined/>}
          onClick={onCall}
          disabled={!Boolean(value)}
        />
      </HStack>
    </div>
  )
}

export default InputPhone
