import React, {memo, useCallback, useMemo, useState} from "react";
import {SettingOutlined} from "@ant-design/icons";
import {ButtonStyled} from "./style";
import TableSettingsModal from "../table-settings-modal/table-settings-modal";
import {TransferItem} from "../../model/types/types";
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {isJsonSettingsByKey} from "../../model/selectors/store";
import {Tooltip} from "antd";

interface TableSettingsButtonProps {
    items: TransferItem[],
}


export const TableSettingsButton: React.FC<TableSettingsButtonProps> = memo((props) => {
    const {items} = props
    const [isOpen, setIsOpen] = useState(false);
    const {pathname} = useLocation();
    const isSettings = useSelector((state) => isJsonSettingsByKey(state, pathname))
    const tooltipTitle = useMemo(() => isSettings ? 'Применены индивидуальные настройки' : 'Индивидуальные настройки для отображения столбцов', [isSettings])

    const onCloseModal = useCallback(() => {
        setIsOpen(false)
    }, [])
    const onOpenModal = useCallback(() => {
        setIsOpen(true)
    }, [])

    if (!items) {
        return null
    }

    return (
        <>
        <Tooltip title={tooltipTitle} placement="topRight">
            <ButtonStyled
                type={isSettings ? 'primary' : 'default'}
                onClick={onOpenModal}
            >
                <SettingOutlined/>
            </ButtonStyled>
        </Tooltip>
            {isOpen && (
                <TableSettingsModal
                    isOpen={isOpen}
                    items={items}
                    onCancel={onCloseModal}
                />
            )}
        </>

    )

})