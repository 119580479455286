import React, { memo, Suspense, useCallback, useState } from 'react'
import { HStack } from '../../../../../../ui/Stack'
import { Button } from 'antd'
import { useSelector } from 'react-redux'
import { classNames } from '../../../../../../lib/classNames/classNames'
import { ModalCreateTask } from 'src/react-app/views/modal-create-task-new'
import { ModalAddNote } from '../../../../../../views/modal-add-notes-new'
import { createSession } from '../../../selectors'

interface AppealsFormsHeaderProps {
  id: string | number,
  className?: string,
  stage?: number,
  isFinished?: boolean
  toOrder?: () => void,
  toClaim?: () => void,
}

const titlesButton = [
  'Взять в работу'
]

export const AppealsFormsHeader: React.FC<AppealsFormsHeaderProps> = memo((props: AppealsFormsHeaderProps) => {
  const { className, id, stage = 0, toOrder, isFinished } = props
  const isDisabledButtons = id === 'new'
  const { sessionData } = useSelector(createSession)

  const [isOpenModalCreateTask, setIsOpenModalCreateTask] = useState<boolean>(false)
  const [isOpenModalAddNote, setIsOpenModalAddNote] = useState<boolean>(false)

  const handleNextStageOnClick = useCallback(async () => {
    if (toOrder) {
      await toOrder?.()
    }
  }, [toOrder])

  const onCloseAddNote = useCallback(() => {
    setIsOpenModalAddNote(false)
  }, [setIsOpenModalAddNote])

  const onCloseCreateTask = useCallback(() => {
    setIsOpenModalCreateTask(false)
  }, [setIsOpenModalCreateTask])

  return (
    <HStack gap={'8'} className={classNames('mapWrapper', {}, [className])}>
      <HStack gap={'8'} max justify={'start'} className={'h3'}>
        {id === 'new' ? ('Новое обращение') : (<>Обращение {`№ ${id}`}</>)}
      </HStack>
      <HStack gap={'8'} max justify={'end'}>
        <Button
          onClick={() => setIsOpenModalAddNote(true)}
          disabled={isDisabledButtons}
        >
          Добавить заметку
        </Button>
        <Suspense fallback={''}>
          <Button
            onClick={() => setIsOpenModalCreateTask(true)}
            disabled={isDisabledButtons}
          >
            Создать задачу
          </Button>
          <ModalCreateTask
            isOpen={isOpenModalCreateTask}
            onClose={onCloseCreateTask}
            entityType={'case'}
            entityId={id}
          />
        </Suspense>
        <ModalAddNote
          entityId={Number(id)}
          entityType={'case'}
          isOpen={isOpenModalAddNote}
          onClose={onCloseAddNote}
        />

        {
          titlesButton[stage] && (
            <Button
              onClick={handleNextStageOnClick}
              style={{marginBottom: 16, marginTop: 16}}
              type='primary'
              disabled={(isDisabledButtons)}
            >
              {titlesButton[stage] ?? ''}
            </Button>
          )
        }
      </HStack>
    </HStack>
  )
})
