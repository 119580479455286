import fetchAPI from '../../../lib/utils/fetch-api'
import {
  setDeliveryId,
  setDeliveriesEnumsData,
  setDeliveriesEnumsFailed,
  setDeliveriesEnumsLoading,
  setDeliveriesFormData,
  setDeliveriesFormFailed,
  setDeliveriesFormLoading,
  setDeliveriesStagePlanData,
  setDeliveriesStagePlanFailed,
  setDeliveriesStagePlanLoading,
  setDeliveriesTimelineData,
  setDeliveriesTimelineFailed,
  setDeliveriesTimelineLoading,
} from './actions'

export function getDeliveriesForm(id) {
  return async dispatch => {
    dispatch(setDeliveriesFormLoading())
    try {
      const response = await fetchAPI(`/api/logistics/deliveries/${id}`) || []
      if (response['errors'] || response?.error) {
        let error = response['errors'] || response?.error
        if (Array.isArray(error)) {
          error = error.join()
        }
        return dispatch(setDeliveriesFormFailed(error))
      }
      return dispatch(setDeliveriesFormData(response))
    } catch (err) {
      console.error(err)
      return dispatch(setDeliveriesFormFailed('При загрузки полей возникла ошибка'))
    }
  }
}

export function nextStage(id, formData) {
  return async dispatch => {
    dispatch(setDeliveriesFormLoading())
    try {
      const response =  await fetchAPI(
        `/api/logistics/deliveries/${id}`,
        { method: 'POST', body: JSON.stringify(formData) }
      )

      console.log('response', response)

      if (response['errors']) {
        let error = response['errors']
        if (Array.isArray(error)) {
          error =  error.join( )
        }
        return dispatch(setDeliveriesFormFailed(error))
      }

      return dispatch(setDeliveriesFormData(response))
    } catch (err) {
      return dispatch(setDeliveriesFormFailed(err as string))
    }
  }
}

export function updateFormData(id, formData) {
  return async dispatch => {
    try {
      const response = await fetchAPI(
        `/api/logistics/deliveries/${id}`,
        {
          method: 'POST', body: JSON.stringify(formData)
        }
      )

      if (response['errors']) {
        let error = response['errors']
        if (Array.isArray(error)) {
          error = error.join()
        }
        return dispatch(setDeliveriesFormFailed(error))
      }

      if ((!id || id === 'new') && response?.id) {
        return dispatch(setDeliveryId(response.id))
      }

      return
    } catch (err) {
      return dispatch(setDeliveriesFormFailed(err as string))
    }
  }
}

export function getDeliveriesEnums(id: string) {
  return async dispatch => {
    dispatch(setDeliveriesEnumsLoading())
    try {
      const response = await fetchAPI(`/api/logistics/deliveries/${id}/enum`)

      if (response['errors']) {
        let error = response['errors']
        if (Array.isArray(error)) {
          error = error.join()
        }
        return dispatch(setDeliveriesEnumsFailed(error))
      }

      return dispatch(setDeliveriesEnumsData(response))
    } catch (err) {
      console.error(err)
      return dispatch(setDeliveriesEnumsFailed('При загрузки enum возникла ошибка'))
    }
  }
}

export function getDeliveriesTimeLineData(id) {
  return async dispatch => {
    dispatch(setDeliveriesTimelineLoading())
    try {
      const response = await fetchAPI(`/api/get-activity/delivery/${id}`) || []

      if (response['errors']) {
        let error = response['errors']
        if (Array.isArray(error)) {
          error = error.join()
        }
        return dispatch(setDeliveriesTimelineFailed(error))
      }

      if (response['items']) {
        response['itemsDates'] = response['items']?.map((itemsByDays) => itemsByDays.date)
      }

      return dispatch(setDeliveriesTimelineData(response))
    } catch (err) {
      console.error(err)
      return dispatch(setDeliveriesTimelineFailed('При загрузки ленты активности возникла ошибка'))
    }
  }
}

export function getDeliveriesStagePlanData(id) {
  return async dispatch => {
    dispatch(setDeliveriesStagePlanLoading())
    try {
      const response = await fetchAPI(`/api/stage-plan/delivery/${id}`) || []

      if (response['errors']) {
        let error = response['errors']
        if (Array.isArray(error)) {
          error = error.join()
        }
        return dispatch(setDeliveriesStagePlanFailed(error))
      }

      return dispatch(setDeliveriesStagePlanData(response))
    } catch (err) {
      console.error(err)
      return dispatch(setDeliveriesStagePlanFailed('При загрузки плана исполнения возникла ошибка'))
    }
  }
}
