import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {
  Alert,
  Button,
  Popconfirm,
  SpinProps,
  Table,
  TablePaginationConfig,
  Typography,
} from 'antd'
import B2BLayout from '../../layouts/b2b'
import fetchAPI from '../../../lib/utils/fetch-api'
import { tableRowClick } from '../../../lib/utils/list'
import { TableWrapper } from './styles'
import dayjs from 'dayjs'
import './index.css'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { objectToUrl } from '../../pages_v2/utils'
import FiltersList from '../../components/v2/filters'
import { ButtonActionWrapper } from '../../components/list-operations/styles'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { CountBallAbsoluteRight, CountBallInline } from '../../components/activity-timeline/styles'
import {getJsonSettingsByKey, TableSettingsButton} from "../../components/v2/table-settings-button";
import {getPrintPath} from "../../components/v2/export-excel-button";
import {HrPrintButton} from "./hr-print-button";
import {HStack} from "../../ui/Stack";


interface EnumItem {
  label: string
  value: number
}

interface Enums {
  [x: string]: EnumItem[]
}

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData,
)

export default function AdminObjectsListPage() {
  const { sessionData } = useSelector(createSession)
  const history = useHistory()
  const [candidates, setCandidates] = useState<any[]>([])
  const [stages, setStages] = useState<any[]>([])
  const [enums, setEnums] = useState<Enums>({})
  const [data, setData] = useState<any[]>([])
  const [pageErrors, setPageErrors] = useState<String[]>([])
  const [loading, setLoading] = useState<boolean | SpinProps | undefined>(false)
  const [urlParams] = useState(Object.fromEntries(new URLSearchParams(location.search)))
  const {pathname} = useLocation();
  const jsonSettings = useSelector((state) => getJsonSettingsByKey(state, pathname));


  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: Number(urlParams?.page) || 1,
    pageSize: 10,
    position: ['bottomCenter'],
  })
  const [sorter, setSorter] = useState<any>({
    sort_field: urlParams?.field,
    sort_order: urlParams?.order,
  })
  const [filters, setFilters] = useState<any>({
    id: urlParams?.id,
    fio: urlParams?.fio,
    phone: urlParams?.phone,

    created_by: urlParams?.created_by && Number(urlParams?.created_by),
    department: urlParams?.department && Number(urlParams?.department),
    vacancy: urlParams?.vacancy && Number(urlParams?.vacancy),
    stage: urlParams?.stage && urlParams?.stage,
    city_id: urlParams?.city_id && Number(urlParams?.city_id),
    region_id: urlParams?.region_id && Number(urlParams?.region_id),
    cancel_reason: urlParams?.cancel_reason && Number(urlParams?.cancel_reason),

    datetime_interview: urlParams?.datetime_interview,
    created_at: urlParams?.created_at,
    start_probation_period: urlParams?.start_probation_period,
    end_probation_period: urlParams?.end_probation_period,
  })

  const breadcrumbs = useMemo(
    () => [
      {
        href: location.pathname,
        title: 'Учет кандидатов',
      },
    ],
    [location.pathname],
  )

  const convertEnumObject = (data: Record<string, any>): Record<string, EnumItem[]> => {
    const convertedData: Record<string, EnumItem[]> = {}

    Object.keys(data).forEach(key => {
      const value = data[key]
      if (Array.isArray(value)) {
        convertedData[key] = value.map((item: { value: string; id: string }) => ({
          label: item.value,
          value: +item.id,
        }))
      } else if (typeof value === 'object') {
        convertedData[key] = Object.keys(value).map(id => ({
          label: value[id],
          value: +id,
        }))
      }
    })
    return convertedData
  }

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const [candidate, stagesList, enums] = await Promise.all([
        fetchAPI(`/api/hr/candidate`),
        fetchAPI(`/api/stages-list?name=hr`, { method: 'GET' }),
        fetchAPI('/api/hr/request/enum'),
      ])
      const e = convertEnumObject(enums.enum)
      setEnums(e)
      if (candidate) {
        setCandidates(candidate as any[])
      }
      if (stagesList) {
        setStages(stagesList as any[])
      }
      await getData()
    })()
  }, [])

  const formatPhone = (num: string) => {
    if (num.length != 11) return null
    return num.replace(/^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/, '+$1 ($2) $3-$4-$5')
  }

  const addDataColumns = useMemo(() => {
    const addDataColumns: any[] = [
      {
        title: 'ID',
        width: 80,
        dataIndex: 'id',
        key: 'id',
        sorter: true,
        fixed: 'left',
        sortOrder: sorter?.sort_field === 'id' ? sorter?.sort_order : null,
      },
      {
        title: 'Стадия',
        width: 160,
        dataIndex: 'stage',
        key: 'stage',
        sorter: true,
        sortOrder: sorter?.sort_field === 'stage' ? sorter?.sort_order : null,
        render: (stage: null | string, data) => {
          if (stages) {
            const current = stages.filter(item => item.code === stage)
            const style = current?.[0]?.['style']
            const { avito_chat_count } = data
            return (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ ...style, padding: '2px 10px', borderRadius: 9 }}>
                  {current?.[0]?.['name'] || stage}
                </div>
                {avito_chat_count ? (
                  <CountBallAbsoluteRight title={'avito_chat_count'} bgColor={'countColor'}>
                    {avito_chat_count}
                  </CountBallAbsoluteRight>
                ) : null}
              </div>
            )
          }
          return stage
        },
      },
      {
        title: 'Чаты',
        width: 50,
        dataIndex: 'chat_count',
        key: 'chat_count',
        sorter: true,
        sortOrder: sorter?.sort_field === 'chat_count' ? sorter?.sort_order : null,
        render: (_, data) => {
          const { chat_count } = data
          return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {chat_count ? (
                <CountBallInline title={'chat_count'} bgColor={'countColor'}>
                  {chat_count > 99 ? 99 : chat_count}
                </CountBallInline>
              ) : (
                '-'
              )}
            </div>
          )
        },
      },
      {
        title: 'Отдел',
        width: 150,
        dataIndex: 'department',
        key: 'department',
        sorter: true,
        sortOrder: sorter?.sort_field === 'department' ? sorter?.sort_order : null,
        render: (v: number) =>
          enums?.department ? enums.department.find(i => i.value === v)?.label : null,
      },
      {
        title: 'Вакансия',
        width: 150,
        dataIndex: 'vacancy',
        key: 'vacancy',
        sorter: true,
        sortOrder: sorter?.sort_field === 'vacancy' ? sorter?.sort_order : null,
      },
      {
        title: 'Менеджер',
        width: 150,
        dataIndex: 'manager_id',
        key: 'manager_id',
        sorter: true,
        sortOrder: sorter?.sort_field === 'manager_id' ? sorter?.sort_order : null,
        render: (v, row) => {
          const manager = v || row.created_by
          return enums?.interview_responsible ? enums.interview_responsible.find(i => i.value === manager)?.label : null
        }
      },
      {
        title: 'Ответственный за собеседование',
        width: 150,
        dataIndex: 'interview_responsible',
        key: 'interview_responsible',
        render: (v: number) =>
          enums?.interview_responsible
            ? enums.interview_responsible.find(i => i.value === v)?.label
            : null,
      },
      {
        title: 'Дата создания',
        width: 150,
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: true,
        sortOrder: sorter?.sort_field === 'created_at' ? sorter?.sort_order : null,
        render: (val: string | null) => val && dayjs(val).format('DD.MM.YYYY HH:mm'),
      },
      {
        title: 'Дата завершения',
        width: 150,
        dataIndex: 'completed_at',
        key: 'completed_at',
        sorter: true,
        sortOrder: sorter?.sort_field === 'completed_at' ? sorter?.sort_order : null,
        render: (val: string | null) => val && dayjs(val).format('DD.MM.YYYY HH:mm'),
      },
      {
        title: 'ФИО кандидата',
        width: 150,
        dataIndex: 'fio',
        key: 'fio',
        sorter: true,
        sortOrder: sorter?.sort_field === 'fio' ? sorter?.sort_order : null,
      },
      {
        title: 'Телефон',
        width: 150,
        dataIndex: 'phone',
        key: 'phone',
        sorter: true,
        sortOrder: sorter?.sort_field === 'phone' ? sorter?.sort_order : null,
        render: v => <>{formatPhone(String(v))}</>,
      },
      {
        title: 'Дата собеседования',
        width: 150,
        dataIndex: 'datetime_interview',
        key: 'datetime_interview',
        sorter: true,
        sortOrder: sorter?.sort_field === 'datetime_interview' ? sorter?.sort_order : null,
        render: (val: string | null) => val && dayjs(val).format('DD.MM.YYYY HH:mm'),
      },
      {
        title: 'Дата задачи',
        width: 150,
        dataIndex: 'task_deadline',
        key: 'task_deadline',
        sorter: true,
        sortOrder: sorter?.sort_field === 'task_deadline' ? sorter?.sort_order : null,
        render: (val: null | string) => val && dayjs(val).format('DD.MM.YYYY HH:mm'),
      },
      {
        title: 'Ресурс',
        width: 150,
        dataIndex: 'resource',
        key: 'resource',
        sorter: true,
        sortOrder: sorter?.sort_field === 'resource' ? sorter?.sort_order : null,
      },
      {
        title: 'Округ',
        width: 150,
        dataIndex: 'federal_district_id',
        key: 'federal_district_id',
        sorter: true,
        sortOrder: sorter?.sort_field === 'federal_district' ? sorter?.sort_order : null,
      },
      {
        title: 'Регион',
        width: 150,
        dataIndex: 'region_id',
        key: 'region_id',
        sorter: true,
        sortOrder: sorter?.sort_field === 'region' ? sorter?.sort_order : null,
      },
      {
        title: 'Город',
        width: 150,
        dataIndex: 'city_id',
        key: 'city_id',
        sorter: true,
        sortOrder: sorter?.sort_field === 'city' ? sorter?.sort_order : null,
      },
      {
        title: 'Район',
        width: 150,
        dataIndex: 'district_id',
        key: 'district_id',
        sorter: true,
        sortOrder: sorter?.sort_field === 'district' ? sorter?.sort_order : null,
      },
      {
        title: 'Дата выхода на работу',
        width: 200,
        dataIndex: 'start_probation_period',
        key: 'start_probation_period',
        sorter: true,
        sortOrder: sorter?.sort_field === 'start_probation_period' ? sorter?.sort_order : null,
        render: (val: string | null) => val && dayjs(val).format('DD.MM.YYYY HH:mm'),
      },
      {
        title: 'Дата окончания исп.срока',
        width: 200,
        dataIndex: 'end_probation_period',
        key: 'end_probation_period',
        sorter: true,
        sortOrder: sorter?.sort_field === 'end_probation_period' ? sorter?.sort_order : null,
        render: (val: null | string) => val && dayjs(val).format('DD.MM.YYYY HH:mm'),
      },
      {
        title: 'Причина отказа',
        width: 150,
        dataIndex: 'cancel_reason',
        key: 'cancel_reason',
        sorter: true,
        sortOrder: sorter?.sort_field === 'cancel_reason' ? sorter?.sort_order : null,
        render: (v: number) =>
          enums?.cancel_reason ? enums.cancel_reason.find(i => i.value === v)?.label : null,
      },
    ]

    if (sessionData.roles.includes(19)) {
      addDataColumns.push({
        title: 'Действия',
        dataIndex: 'operation',
        width: 80,
        align: 'center',
        fixed: 'right',
        render: (_: any, record) => (
          <span
            style={{ display: 'flex', justifyContent: 'center' }}
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <Typography.Link>
              <ButtonActionWrapper title="Удалить">
                <Popconfirm title="Удалить поле?" onConfirm={() => remove(record.id)}>
                  <DeleteOutlined />
                </Popconfirm>
              </ButtonActionWrapper>
            </Typography.Link>
          </span>
        ),
      })
    }

    return addDataColumns
  }, [candidates, stages, sorter, sessionData])

  /**
   * Удаляет запись
   * @param id
   */
  const remove = id => {
    fetchAPI(`/api/hr/request/${id}`, { method: 'DELETE' })
      .then(() => {
        getData()
      })
      .catch(e => {
        console.error({ e })
      })
  }

  // @ts-ignore
  const finalColumns = useMemo(() => [].concat(addDataColumns).filter(item => item.title), [addDataColumns])
  const viewColumns = useMemo(() => {
    if (jsonSettings && jsonSettings?.length > 0) {
      let result = []
      for (let i = 0; i < jsonSettings.length; i++) {
        // @ts-ignore
        let item = finalColumns.find((column) => jsonSettings[i] === column.dataIndex)
        if (item) {
          result.push(item)
        }
      }
      return result;
    }
    return finalColumns;
  }, [finalColumns, jsonSettings]);

  const settingsColumns = useMemo(() => finalColumns.map((column) => ({
        // @ts-ignore
        key: column?.dataIndex, title: column?.title,
      })), [finalColumns])

  const cbTableRowClick = useCallback((item) => item['id'] && history.push(`/hr/${item['id']}`), [history])

  const getData = useCallback(async () => {
    setLoading(true)
    try {
      const currentUrlParams = Object.fromEntries(new URLSearchParams(location.search))
      fetchAPI(`/api/hr/request?${objectToUrl(currentUrlParams)}`)
        .then(({ data, pagination, sorter }) => {
          setPagination(prevState => ({
            ...prevState,
            ...pagination
          }))
          setSorter(sorter)
          setData(data)
          setFilters({
            id: currentUrlParams?.id,
          })
        }).then(() => {
          setLoading(false)
        })
        .catch(e => {
          setPageErrors([e])
          setLoading(false)
        })
    } catch (error) {
      console.error('Ошибка при получении данных:', error)
    }
  }, [pagination])

  const rowClassName = useCallback(record => {
    return record?.['is_read'] ? 'table-row-dark' : 'table-row-light'
  }, [])


  /**
   * Отслеживает изменение состояния таблицы
   *
   * @param pagination
   * @param filters
   * @param sorter
   */
  const handlerTableChange = useCallback(async (pagination, filters, sorter) => {
    const currentUrlParams = Object.fromEntries(new URLSearchParams(location.search))
    setPagination(prevState => ({...prevState, ...pagination}))

    const current = {
      ...currentUrlParams,
      sort_order: sorter?.order,
      sort_field: sorter?.field,
      page: pagination.current,
      pageSize: pagination.pageSize
    }
    if (current?.page === 1) {
      delete current.page
    }

    if (current?.sort_order === undefined || current?.sort_order === null) {
      delete current.sort_order
      delete current.sort_field
    }
    const urlParams = new URLSearchParams(current).toString()
    history.push({ search: urlParams })
    await getData()
  }, [])

  /**
   * Отслеживает состояние фильтров
   * @param filter
   */
  const handlerFilterChange = useCallback( async (filter) => {
    let tmpFilter = {...(filter || {}), pageSize: pagination.pageSize }
    const newUrlParams = new URLSearchParams(tmpFilter).toString()
    history.push({ search: newUrlParams })
    await getData()
  }, [pagination])


  const excelPath = useMemo(() => {
    return getPrintPath('/api/hr/request', new URLSearchParams(location.search))
  }, [location.search])


  const onButtonCreate = useCallback(() => history.push(`/hr/new`), [history])

  return (
    <B2BLayout breadcrumbs={breadcrumbs} isViewScrollButton>
      <div>
        <Button onClick={onButtonCreate} style={{ marginBottom: 16 }}>
          <PlusOutlined /> Создать
        </Button>
      </div>
      <div>
        <FiltersList
          filters={[
            {
              name: 'id',
              placeholder: 'ID',
              type: 'input-integer',
              value: filters?.id || undefined,
              options: {
                width: '170px',
                tooltip: 'ID',
              },
            },
            {
              name: 'phone',
              placeholder: 'Телефон',
              type: 'phone',
              value: filters?.phone || undefined,
              options: {
                width: '170px',
                tooltip: 'Телефон',
              },
            },
            {
              name: 'fio',
              placeholder: 'ФИО',
              type: 'input-string',
              value: filters?.fio || undefined,
              options: {
                width: '170px',
                tooltip: 'ФИО',
              },
            },
            {
              name: 'created_by',
              placeholder: 'Менеджер',
              type: 'select',
              value: filters?.created_by || undefined,
              options: {
                width: '170px',
                enum: enums?.interview_responsible,
                tooltip: 'Менеджер',
              },
            },
            {
              name: 'department',
              placeholder: 'Отдел',
              type: 'select',
              value: filters?.department || undefined,
              options: {
                width: '170px',
                enum: enums.department,
                tooltip: 'Отдел',
              },
            },
            {
              name: 'vacancy',
              placeholder: 'Вакансия',
              type: 'select',
              value: filters?.vacancy || undefined,
              options: {
                width: '170px',
                enum: enums.vacancy,
                tooltip: 'Вакансия',
              },
            },
            {
              name: 'stage',
              placeholder: 'Стадия',
              type: 'select',
              value: filters?.stage || undefined,
              options: {
                width: '170px',
                enum: stages.map(item => ({ label: item.name, value: item.code })),
                tooltip: 'Стадия',
              },
            },
            {
              name: 'federal_district_id',
              placeholder: 'Округ',
              type: 'select',
              value: filters?.federal_district_id || undefined,
              options: {
                width: '170px',
                enum: enums.federal_district_id,
                tooltip: 'Округ',
              },
            },
            {
              name: 'region_id',
              placeholder: 'Регион',
              type: 'select',
              value: filters?.region_id || undefined,
              options: {
                width: '170px',
                enum: enums.region_id,
                tooltip: 'Регион',
              },
            },
            {
              name: 'city_id',
              placeholder: 'Город',
              type: 'select',
              value: filters?.city_id || undefined,
              options: {
                width: '170px',
                enum: enums.city_id,
                tooltip: 'Город',
              },
            },
            {
              name: 'district_id',
              placeholder: 'Район',
              type: 'select',
              value: filters?.district_id || undefined,
              options: {
                width: '170px',
                enum: enums.district_id,
                tooltip: 'Район',
              },
            },
            {
              name: 'resource',
              placeholder: 'Ресурс',
              type: 'select',
              value: filters?.resource || undefined,
              options: {
                width: '170px',
                enum: enums.resource,
                tooltip: 'Ресурс',
              },
            },
            {
              name: 'cancel_reason',
              placeholder: 'Причина отказа',
              type: 'select',
              value: filters?.cancel_reason || undefined,
              options: {
                width: '170px',
                enum: enums.cancel_reason,
                tooltip: 'Причина отказа',
              },
            },
            {
              name: 'datetime_interview',
              placeholder: ['Начальная', 'Конечная'],
              type: 'dates',
              value: filters?.datetime_interview || undefined,
              options: {
                width: '220px',
                tooltip: 'Дата собеседования',
              },
            },
            {
              name: 'created_at',
              placeholder: ['Начальная', 'Конечная'],
              type: 'dates',
              value: filters?.created_at || undefined,
              options: {
                width: '220px',
                tooltip: 'Дата создания',
              },
            },
            {
              name: 'start_probation_period',
              placeholder: ['Начальная', 'Конечная'],
              type: 'dates',
              value: filters?.start_probation_period || undefined,
              options: {
                width: '220px',
                tooltip: 'Дата выхода на работу',
              },
            },
            {
              name: 'end_probation_period',
              placeholder: ['Начальная', 'Конечная'],
              type: 'dates',
              value: filters?.end_probation_period || undefined,
              options: {
                width: '220px',
                tooltip: 'Дата окончания исп.срока',
              },
            },
          ]}
          onChange={handlerFilterChange}
        />
      </div>
      {pageErrors.length > 0 ? (
        <div style={{ marginBottom: 40 }}>
          <Alert
            message="При выполнении операции возникла ошибка:"
            showIcon
            type="error"
            description={pageErrors.join('. ')}
          />
        </div>
      ) : null}

      <TableWrapper>
        <HStack gap={'24'} align={'start'} justify={'end'} style={{marginBottom: '20px'}}>
          <HrPrintButton
              path={excelPath}
              columns={viewColumns}
              enums={enums}
              stages={stages}
          />
          <TableSettingsButton items={settingsColumns}/>
        </HStack>

        <Table
          rowKey={'id'}
          columns={viewColumns}
          rowClassName={rowClassName}
          size="small"
          dataSource={data}
          scroll={{ x: 'max-content' }}
          pagination={pagination}
          loading={loading}
          onHeaderRow={() => ({ style: { fontSize: 13 } })}
          onChange={handlerTableChange}
          onRow={record => ({ onClick: event => tableRowClick(event, record, cbTableRowClick) })}
        />
      </TableWrapper>
    </B2BLayout>
  )
}
