import dayjs from "dayjs";

const months = {
    'Январь': 0,
    'Февраль': 1,
    'Март': 2,
    'Апрель': 3,
    'Май': 4,
    'Июнь': 5,
    'Июль': 6,
    'Август': 7,
    'Сентябрь': 8,
    'Октябрь': 9,
    'Ноябрь': 10,
    'Декабрь': 11,
}
export const isDateInRange = (targetDate: string, selectedMonths: string[], selectedYears: string[]) => {
    if (selectedMonths.length === 0 && selectedYears.length === 0) {
        return true;
    }
    const target = dayjs(targetDate);
    const targetYear = target.year();

    if (selectedMonths.length === 0 && selectedYears.length !== 0) {
       return selectedYears.map((year) => Number(year)).includes(targetYear);
    }

 //   const dateLen = targetDate.length;
    let targetMonth = target.month();
    // /*
    // * решение проблемы с переводом подобных дат в другой месяц 2024-01-31T21:30:11.351Z
    // * */
    // if (dateLen > 10) {
    //     targetMonth = dayjs(targetDate.slice(0, 10)).month();
    // }

    //@ts-ignore
    const mapM: number[] = selectedMonths.map((m) => months[m]);
    if (selectedMonths.length !== 0 && selectedYears.length === 0) {
        return mapM.includes(targetMonth);
    }

     return mapM.includes(targetMonth) && selectedYears.map((year) => Number(year)).includes(targetYear);
}
