import React, { useCallback, useEffect, useState } from 'react'
import { Table, Button, Popconfirm, Input, Select, message } from 'antd'
import fetchAPI from '../../../../../lib/utils/fetch-api'
import InputPhone from '../../../../components/input-phone'
import { SaveTwoTone, CloseSquareTwoTone, DeleteTwoTone, EditTwoTone, PhoneOutlined } from '@ant-design/icons'
import './index.css'
import { formatPhoneNumber } from '../../../orders/list/columns'
import { HStack } from '../../../../ui/Stack'
import { TelegramIcon, WhatsappIcon } from '../../../../assets/icons'
import { modalPhone } from '../../../../components/input-phone-client/mango-call'
import { Value } from '../../../elements'

const { TextArea } = Input

interface IEnum {
  id: number
  value: string
}

interface IData {
  id?: number
  type_id: null | number
  value: null | string
  comment: null | string
  key: number | string
}

const ContactsTable: React.FC<{ userId: number }> = ({ userId }) => {
  const [data, setData] = useState<IData[]>([])
  const [enumData, setEnumData] = useState<IEnum[]>([])
  const [editingKey, setEditingKey] = useState<number | string | null>(null)
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    const loadData = async () => {
      const response = await fetchSources(userId)
      setData(response.data.map(item => ({ ...item, key: item.id })))
      setEnumData(response.enums)
    }
    loadData()
  }, [userId])

  const handleAdd = () => {
    const newKey = Date.now()
    const newRecord: IData = {
      key: newKey,
      type_id: null,
      value: null,
      comment: null,
    }
    setData([newRecord, ...data])
    setEditingKey(newKey)
    setIsEditing(true)
  }

  const saveRecord = async (record: IData) => {
    if (!record.type_id || !record.value) {
      return
    }

    const response = await saveData(record)
    if (response.error) {
      message.error('Ошибка сохранения. Попробуйте еще раз.')
      return
    }

    const updatedData = data.map((item) => {
      if (item.key === record.key) {
        if (response.data) {
          item.id = response.data.id
          item.type_id = response.data.type_id
          item.value = response.data.value
          item.comment = response.data.comment
        }
      }
      return item
    })

    setData(updatedData)
    setEditingKey(null)
    setIsEditing(false)
  }

  const handleSave = (record: IData) => {
    saveRecord(record)
  }

  const isEditingRow = (key: number | string) => editingKey === key

  const edit = (key: number | string) => {
    setEditingKey(key)
    setIsEditing(true)
  }

  const cancel = () => {
    setEditingKey(null)
    setIsEditing(false)
  }

  const saveData = async (record: IData) => {
    try {
      const response = await fetchAPI(
        `/api/customer/save-contact/${userId}`,
        { method: 'POST', body: JSON.stringify(record) },
      )
      return { data: response }
    } catch (error) {
      return { error: true }
    }
  }

  const deleteRecord = async (id: number | string) => {
    const response = await fetchAPI(`/api/customer/delete-contact/${id}`, { method: 'DELETE' })
    if (response?.status === 'ok') {
      setData(data.filter(item => item.id !== id))
    }
  }

  const handleTypeChange = (value: number, record: IData) => {
    const updatedData = data.map((item) => {
      if (item.key === record.key) {
        item.type_id = value
        if (value === 859) {
          item.value = ''
        }
      }
      return item
    })
    setData(updatedData)
  }

  const onCall = useCallback(phone => {
    modalPhone({ phone })
  }, [])

  const columns = [
    {
      title: 'Тип контакта',
      dataIndex: 'type_id',
      width: 300,
      render: (text: null | number, record: IData) => {
        const options = enumData.map(e => (
          <Select.Option key={e.id} value={e.id}>{e.value}</Select.Option>
        ))
        if (isEditingRow(record.key)) {
          return (
            <Select
              defaultValue={text}
              style={{ width: '100%' }}
              onChange={value => handleTypeChange(value, record)}
            >
              {options}
            </Select>
          )
        }
        const type = enumData.find(e => e.id === text)
        return type ? type.value : 'Не установлен'
      },
    },
    {
      title: 'Значение',
      dataIndex: 'value',
      width: 350,
      render: (text: string | null, record: IData) => {
        if (isEditingRow(record.key)) {
          if (record?.type_id === 859) {
            return <InputPhone
              UISchema={{}}
              value={text}
              onChange={(e: null | string) => (record.value = e && e?.length > 0 ? e : null)}
            />
          }
          return <Input
            defaultValue={text || ''}
            onChange={e => (record.value = e.target.value)}
          />
        }
        if (record?.type_id === 859) {
          const phone = text ? text.replace(/\D/g, '') : undefined
          const phoneNumber = formatPhoneNumber(phone)
          return (
            <HStack justify='between'>
              {phoneNumber}
              <HStack justify='between' gap='4'>
                <Button
                  type='link'
                  href={`https://t.me/+${phone?.replace(/\D/g, '')}`}
                  target='_blank'
                  style={{ padding: '0', display: 'flex', overflow: 'hidden', border: 'none' }}
                  disabled={!Boolean(phone)}
                  icon={<TelegramIcon disabled={!Boolean(phone)}/>}
                />
                <Button
                  type='link'
                  href={`https://wa.me/+${phone?.replace(/\D/g, '')}`}
                  target='_blank'
                  style={{ padding: '0', display: 'flex', overflow: 'hidden', border: 'none' }}
                  disabled={!Boolean(phone)}
                  icon={<WhatsappIcon disabled={!Boolean(phone)}/>}
                />
                <Button
                  type='primary'
                  icon={<PhoneOutlined/>}
                  onClick={() => onCall(phone?.replace(/\D/g, ''))}
                  disabled={!Boolean(phone)}
                />
              </HStack>
            </HStack>
          )
        }
        return text || 'Не указано'
      },
    },
    {
      title: 'ФИО, Комментарий',
      dataIndex: 'comment',
      render: (text: string | null, record: IData) => {
        if (isEditingRow(record.key)) {
          return <TextArea defaultValue={text || ''} onChange={(e) => (record.comment = e.target.value)} />
        }
        return text || <Value data={text} emptyText='не указано'/>
      },
    },
    {
      title: 'Действия',
      width: 250,
      className: 'actions-column',
      render: (_: any, record: IData) => {
        const editable = isEditingRow(record.key)
        return editable ? (
          <div className='actions'>
            <SaveTwoTone twoToneColor={'#1b9444'} onClick={() => handleSave(record)} title='Сохранить' />
            <CloseSquareTwoTone twoToneColor={'#ff5252'} onClick={cancel} title='Отмена' />
          </div>
        ) : (
          <div className='actions'>
            <Button onClick={() => edit(record.key)} disabled={editingKey !== null}>
              <EditTwoTone twoToneColor={editingKey !== null ? '#888888' : '' }/>
            </Button>
            <Popconfirm
              title='Вы уверены, что хотите удалить?'
              onConfirm={() => deleteRecord(record.id!)}
              okText='Да'
              cancelText='Нет'
              disabled={editingKey !== null}
            >
              <Button disabled={editingKey !== null}>
                <DeleteTwoTone twoToneColor={editingKey === null ? '#ff5252' : '#888888'}/>
              </Button>
            </Popconfirm>
          </div>
        )
      },
    },
  ]

  return (
    <>
      <Button type='primary' onClick={handleAdd} style={{ marginBottom: 16 }}>Добавить</Button>
      <Table dataSource={data} columns={columns} rowKey='key' />
    </>
  )
}

async function fetchSources(userId: number) {
  return fetchAPI(`/api/customer/get-contacts/${userId}`)
}

export default ContactsTable
