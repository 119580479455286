import {createSelector} from "reselect";

const selectSession = state => state.session

const selectSessionData = createSelector(
    selectSession,
    ({sessionData}) => sessionData,
)
export const selectJsonSettings = createSelector(
    selectSessionData,
    ({jsonSettings}) => jsonSettings
)

export const getJsonSettingsByKey = createSelector(
    selectJsonSettings,
    (_, key: string) => key,
    (jsonSettings, key) => jsonSettings[key] || []
)

export const isJsonSettingsByKey = createSelector(
    selectJsonSettings,
    (_, key: string) => key,
    (jsonSettings, key) => {
        return !!jsonSettings[key] && jsonSettings[key]?.length !== 0
    }
)