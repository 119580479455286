import React, { useState, useEffect } from 'react'
import { Button, Layout, Row, Col, Skeleton } from 'antd'
import { useHistory } from 'react-router-dom'
import YearFilter from './components/year-filter'
import MonthFilter from './components/month-filter'
import ResourceFilter from './components/resource-filter'
import DepartmentFilter from './components/department-filter'
import VacancyFilter from './components/vacancy-filter'
import ManagerFilter from './components/manager-filter'
import Statistics from './components/statistics'
import B2BLayout from '../../layouts/b2b'
import {
  MapContent,
  MapTitle,
  MapWrapper,
  TaskWrapper,
  FiltersContainer,
  TableContainer,
} from './styles'
import { IStatisticsProps } from './interfaces/interfaces'
import { useFetchData } from './hooks/useFetchData'
import CancelReasonsPieChart from './components/cancelreasonspiechart'
import { monthOrder } from './constants'

const { Content, Sider } = Layout

const breadcrumbs = [
  {
    href: '/hr',
    title: 'Отдел персонала',
  },
  {
    title: 'Воронка откликов',
  },
]

export default function AdminNewObjectPage() {
  const history = useHistory()
  const [selectedYears, setSelectedYears] = useState<string[]>([])
  const [selectedMonths, setSelectedMonths] = useState<string[]>([])
  const [selectedResources, setSelectedResources] = useState<string[]>([])
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([])
  const [selectedVacancies, setSelectedVacancies] = useState<string[]>([])
  const [selectedManagers, setSelectedManagers] = useState<string[]>([])
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)

  const {
    stagesData,
    years,
    resources,
    departments,
    vacancies,
    managers,
    loading,
    cancelReasons,
    arResultVacancy,
  } = useFetchData(
    selectedYears,
    selectedMonths,
    selectedResources,
    selectedDepartments,
    selectedVacancies,
    selectedManagers,
  )

  useEffect(() => {
    if (years.length > 0 && isFirstLoad) {
      const currentYear = new Date().getFullYear().toString()
      setSelectedYears([currentYear])
      setIsFirstLoad(false)
    }
  }, [years, isFirstLoad])

  const handleYearChange = (years: string[]) => {
    setSelectedYears(years)
  }

  const handleMonthChange = (months: string[]) => {
    setSelectedMonths(months)
  }

  const handleResourceChange = (resources: string[]) => {
    setSelectedResources(resources)
  }

  const handleDepartmentChange = (departments: string[]) => {
    setSelectedDepartments(departments)
  }

  const handleVacancyChange = (vacancies: string[]) => {
    setSelectedVacancies(vacancies)
  }

  const handleManagerChange = (managers: string[]) => {
    setSelectedManagers(managers)
  }

  const handlePaymentsClick = () => {
    history.push('/hr/payments')
  }

  const handleClearAllFilters = () => {
    const currentYear = new Date().getFullYear().toString()
    setSelectedYears([currentYear])
    setSelectedMonths([])
    setSelectedResources([])
    setSelectedDepartments([])
    setSelectedVacancies([])
    setSelectedManagers([])
  }

  const totalResponses = stagesData.reduce((acc, data) => acc + data.total, 0)
  const totalInterviews = stagesData.reduce((acc, data) => acc + data.interview, 0)
  const totalRejected = stagesData.reduce((acc, data) => acc + data.rejected, 0)
  const totalAccepted = stagesData.reduce((acc, data) => acc + data.accepted, 0)

  stagesData.sort((a, b) => {
    return monthOrder.map(m=>m.value).indexOf(a.month) - monthOrder.map(m=>m.value).indexOf(b.month)
  })

  const statistics: IStatisticsProps = {
    totalResponses,
    totalAccepted,
    totalRejected,
    totalInterviews,
    stagesData,
    loading,
  }

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <MapWrapper>
        <TaskWrapper>
          <MapTitle>Воронка откликов</MapTitle>
          <Button type='primary' onClick={handlePaymentsClick}>
            Оплаты
          </Button>
        </TaskWrapper>
        <Layout>
          <Content style={{ padding: '20px' }}>
            <Statistics {...statistics} />
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <MapContent style={{ overflow: 'hidden' }}>
                  <TableContainer style={{ overflow: 'hidden' }}>
                    {loading ? (
                      <Skeleton active />
                    ) : (
                      <CancelReasonsPieChart cancelReasons={cancelReasons} arResultVacancy={arResultVacancy} />
                    )}
                  </TableContainer>
                </MapContent>
              </Col>
            </Row>
          </Content>
          <Sider width={300} style={{ background: '#f0f2f5', padding: '20px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h3>Фильтры</h3>
              <Button onClick={handleClearAllFilters} type='default'>
                Очистить все
              </Button>
            </div>
            <FiltersContainer>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <YearFilter
                    selectedYears={selectedYears}
                    onYearChange={handleYearChange}
                    years={years}
                  />
                </Col>
                <Col span={24}>
                  <MonthFilter
                    selectedMonths={selectedMonths}
                    onMonthChange={handleMonthChange}
                    months={monthOrder}
                  />
                </Col>
                <Col span={24}>
                  <ResourceFilter
                    selectedResources={selectedResources}
                    onResourceChange={handleResourceChange}
                    resources={resources}
                  />
                </Col>
                <Col span={24}>
                  <DepartmentFilter
                    selectedDepartments={selectedDepartments}
                    onDepartmentChange={handleDepartmentChange}
                    departments={departments}
                  />
                </Col>
                <Col span={24}>
                  <VacancyFilter
                    selectedVacancies={selectedVacancies}
                    onVacancyChange={handleVacancyChange}
                    vacancies={vacancies}
                  />
                </Col>
                <Col span={24}>
                  <ManagerFilter
                    selectedManagers={selectedManagers}
                    onManagerChange={handleManagerChange}
                    managers={managers}
                  />
                </Col>
              </Row>
            </FiltersContainer>
          </Sider>
        </Layout>
      </MapWrapper>
    </B2BLayout>
  )
}
