import { IChatAvitoState } from './interfaces'
import {
    CHAT_AVITO_LOADING,
    CHAT_AVITO_DATA,
    CHAT_AVITO_FAILED, CHAT_AVITO_ADD_ITEM, SET_ACTIVE_AVITO_CHAT_ID,
} from './actions'
import { IAvitoChat } from '../../pages/card-hr/new/ui/hr-chats/types'


const initialState: IChatAvitoState = {
    data: [],
    isLoading: false,
    error: '',
}

export const chatAvitoReducer = (
    state = initialState,
    {type, payload}: any,
) => {
    switch (type) {
        case CHAT_AVITO_LOADING:
            return {
                ...state,
                isLoading: true,
                error: ''
            }
        case CHAT_AVITO_DATA:
            return {
                ...state,
                data: payload,
                isLoading: false,
                error: ''
            }
        case CHAT_AVITO_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload
            }
        case CHAT_AVITO_ADD_ITEM:
            const newChatItem = payload as IAvitoChat;
            let chats: IAvitoChat[] = state.data || [];

            function mergeObjects(currData: IAvitoChat[], newChatItem: IAvitoChat): IAvitoChat[] {
                let foundMatchingChatId = false

                for (const chatItem of currData) {
                    if (chatItem.chat_id === newChatItem.chat_id) {
                        chatItem.chat.push(newChatItem.chat[0])
                        chatItem.count_not_read = newChatItem.count_not_read
                        foundMatchingChatId = true
                        break;
                    }
                }

                if (!foundMatchingChatId) {
                    currData.push(newChatItem)
                }

                return currData
            }

            const newData = mergeObjects(chats, newChatItem)
            return {
                ...state,
                data: [...newData],
                isLoading: false,
                error: ''
            }
        case SET_ACTIVE_AVITO_CHAT_ID:
            return {
                ...state,
                activeChatId: payload
            }
        default:
            return state
    }
}
