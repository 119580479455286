import styled from 'styled-components';

interface IconWrapperStyled {
    disabled?: boolean;
}

export const IconWrapper = styled.div<IconWrapperStyled>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.disabled ? '#CACACA' : '#009CE7'};
  background: ${props => props.disabled ? '#f5f5f5' : '#009CE7'};
  padding: 15%;
`;

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 10%;
  background: #fff;
`;
