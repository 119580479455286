import React, { useState, useEffect, useMemo, useCallback } from 'react'
import B2BLayout from '../../../layouts/b2b'
import {Table, message, Modal} from 'antd'
import { getColumns } from './columns'
import fetchAPI from '../../../../lib/utils/fetch-api'
import {Link, useHistory} from 'react-router-dom'
import FiltersList from '../../../components/v2/filters'
import { Sorter } from '../../../interfaces'
import { objectToUrl } from '../../utils'
import { currentFiltersProps, Enums, TablePaginationPosition, DataType, IStage } from './interface'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import './index.css'
import ActionButtons from "./action-buttons";
import {VehicleModal} from "../card/modals/modal-vehicle";

const createSession = createSelector(
  (state: any) => state.menuV2,
  counters => counters,
)

const DeliveriesList = () => {
  const history = useHistory()
  const [data, setData] = useState<DataType[]>([])
  const [availableFilters, setAvailableFilters] = useState<Enums>({
    manager: [],
    stage: [],
    organization: [],
    vehicle: [],
  })
  const [urlParams] = useState(Object.fromEntries(new URLSearchParams(location.search)))
  const [currentFilters] = useState<currentFiltersProps>({
    client_phone: urlParams?.client_phone,
    id: urlParams.id ? +urlParams.id : undefined,
    stage: urlParams?.stage,
    dates: urlParams.dates,
    order_reference: urlParams.order_reference,
    vehicle_id: urlParams.vehicle_id ? +urlParams.vehicle_id : undefined,
  })

  /*
  const { sessionData } = useSelector(createSession)
  const isLeader = getDataOfType(sessionData, 'organization.id', Number, null) === 1
  */
  const isLeader = true
  const [loading, setLoading] = useState(false)
  const [bottomCenter] = useState<TablePaginationPosition>('bottomCenter')
  const [stages, setStages] = useState<any>(null)
  const [sorter, setSorter] = useState<Sorter>({})
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 10,
    position: [bottomCenter],
    showSizeChanger: true,
  })
  const {counters} = useSelector(createSession)
  const [localCounter, setLocalCounter] = useState<null | number>(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [isOpenVehicleModal, setIsOpenVehicleModal] = useState(false)
  const [enums, setEnums] = useState<any>({})

  const areObjectsIdentical = (obj1: Object, obj2: Object): boolean => {
    if (obj1 === obj2) return true

    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) {
      return false
    }

    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)

    if (keys1.length !== keys2.length) return false

    for (const key of keys1) {
      if (!keys2.includes(key) || !areObjectsIdentical(obj1[key], obj2[key])) {
        return false
      }
    }

    return true
  }

  useEffect( () => {
    // устанавливает в локальную переменную текущее состояние счетчиков
    const newCounterData = counters?.['/deliveries']
    if (newCounterData && !localCounter) {
      setLocalCounter(newCounterData)
    }

    // устанавливает в локальную переменную обновленное состояние счетчиков и подгружает свежие данные, если нужный счетчик обновился
    if (newCounterData && localCounter && newCounterData !== localCounter) {
      setLocalCounter(newCounterData)
      getData()
    }
  }, [counters])

  /**
   * Хлебные крошки
   */
  const breadcrumbs = useMemo(() => {
    return [
      {
        href: '/logistics/deliveries',
        title: 'Логистика',
      },
      {
        href: location.pathname,
        title: 'Доставки',
      }
    ]
  }, [])

  useEffect(() => {
    try {
      /* Пытаюсь получить стадии и фильтры */
      getStages()
    } catch (e) {
      alert('Не удалось получить данные, пожалуйста, перезагрузите страницу')
    }
  }, [])

  useEffect(() => {
    if (stages) {
      getData()
      getAvailableFilters()
    }
  }, [stages])

  /**
   * Получает стадии
   */
  const getStages = useCallback(() => {
    if (!stages) {
      fetchAPI('/api/get-stages/delivery')
        .then(data => {
          setStages(data)
        })
        .catch(() => {
          console.error('Не удалось получить стадии')
        })
    }
  }, [])

  /**
   * Удаляет запись
   */
  const deleteRecord = useCallback(currentId => {
    fetchAPI(`/api/logistics/deliveries/${currentId}`, { method: 'DELETE' })
      .then(() => {
        getStages()
      })
      .catch(() => {
        console.error('Не удалось удалить запись')
      })
  }, [])

  /**
   * Обновляет состояние пагинации
   */
  const updatePagination = useCallback(
    newState => {
      setPagination({
        ...pagination,
        ...newState,
      })
    },
    [pagination, setPagination],
  )

  /**
   * Получает список доступных фильтров для доставок
   */
  const getAvailableFilters = useCallback(() => {
    const enumsList = { types: ['logistics_vehicle'] }
    fetchAPI(`/api/enum-list?${objectToUrl(enumsList)}`)
      .then(data => {
        setEnums(data)

        const filters = {
          stage: stages.map((i: IStage) => ({ label: i.name, value: i.code })),
          vehicle: data?.logistics_vehicle?.map((i) => ({ label: i.value, value: i.id })),
        }
        setAvailableFilters(filters)
      })
      .catch(() => {
        console.error('Не удалось получить список доступных фильтров')
      })
  }, [stages])

  /**
   * Получает записи
   */
  const getData = useCallback(() => {
    if (!stages) {
      getStages()
    }
    try {
      setLoading(true)
      const urlParams = Object.fromEntries(new URLSearchParams(location.search))
      fetchAPI(`/api/list/delivery?${objectToUrl(urlParams)}`).then(({ data, pagination, sorter }) => {
        if (data) {
          setData(
            data.map(item => {
              return {
                ...item,
                deleteRecord,
                stage: stages && stages.find(stage => stage.code === item.stage),
              }
            }),
          )
          updatePagination(pagination)
          setSorter(sorter)
        }
        setLoading(false)
      })
    } catch (error) {
      console.error('Ошибка при получении данных:', error)
    }
  }, [deleteRecord, stages, updatePagination, loading])

  /**
   * Отслеживает изменение состояния таблицы
   *
   * @param pagination
   * @param _filters
   * @param sorter
   */
  const handleTableChange = (pagination, _filters, sorter) => {
    const currentUrlParams = Object.fromEntries(new URLSearchParams(location.search))
    const current = {
      ...currentUrlParams,
      sort_order: sorter?.order,
      sort_field: sorter?.field,
      page: pagination.current,
      pageSize: pagination.pageSize
    }
    if (current?.page === 1) {
      delete current.page
    }
    if (current?.sort_order === undefined) {
      delete current.sort_order
      delete current.sort_field
    }
    const urlParams = new URLSearchParams(current).toString()
    history.push({ search: urlParams })
    getData()
  }

  /**
   * Отправляет на страницу деталки
   * @param record
   */
  const handleRowClick = record => {
    const { id } = record
    history.push(`/logistics/deliveries/${id}`)
  }

  /**
   * Отслеживает состояние фильтров
   * @param filters
   */
  const changeFiltersHandler = useCallback(filters => {
    let tmpFilter = {...(filters || {}), pageSize: pagination.pageSize }
    const newUrlParams = new URLSearchParams(tmpFilter).toString()
    history.push({ search: newUrlParams })
    getData()
  }, [pagination])


  /**
   * Загрузка csv
   * @param file
   */
  const handleUpload = useCallback(async (file: File) => {
    const formData = new FormData()
    formData.append('file', file)

    try {
      setLoading(true)
      const response = await fetch('/api/logistics/deliveries/upload-csv', {
        method: 'POST',
        body: formData,
      })

      if (response.ok) {
        message.success('Файл успешно загружен')
        getData()
      } else {
        const error = await response.text()
        message.error(`Ошибка загрузки файла ${error}`)
      }
    } catch (error) {
      console.error('Ошибка загрузки файла', error)
      message.error('Ошибка загрузки файла')
    } finally {
      setLoading(false)
    }
  }, [getData])

  const onSelectChange = (selectedKeys: React.Key[]) => {
    setSelectedRowKeys(selectedKeys)
  }

  /**
   * Отправить в Шедекс
   * @param file
   */
  const handleSendToSchedex = useCallback(async () => {
    try {
      setLoading(true)
      const response = await fetchAPI('/api/schedex/orders/export', {
        method: 'POST',
        body: JSON.stringify({ orders: selectedRowKeys }),
        headers: { 'Content-Type': 'application/json' }
      })

      if (response.success) {
        if (response.result.failed_orders.length > 0) {
          const errorMessages = response.result.failed_orders.map(order => (
            <div key={order.id}>
              <a href={`/logistics/deliveries/${order.id}`} target="_blank" rel="noopener noreferrer">
                <b>{order.order_reference}</b>
              </a>
              {`: ${order.errors.join(', ')}`}
            </div>
          ))

          Modal.error({
            title: 'Ошибки при выгрузке',
            content: <div>{errorMessages}</div>,
            onOk() {},
          })
        } else {
          message.success('Доставки успешно отправлены в Шедекс')
        }

        setSelectedRowKeys([])
        getData()
      } else {
        message.error(response.error || 'Ошибка отправки в Шедекс', 5)
      }
    } catch (error) {
      console.error('Ошибка отправки:', error)
      message.error('Ошибка отправки в Шедекс')
    } finally {
      setLoading(false)
    }
  }, [setSelectedRowKeys, getData, setLoading, selectedRowKeys])

  /**
   * Выгрузить из Шедекс
   * @param file
   */
  const handleUnloadFromSchedex = useCallback(async () => {
    try {
      setLoading(true)
      const response = await fetchAPI('/api/schedex/orders/import', {
        method: 'POST',
        body: JSON.stringify({ orders: selectedRowKeys }),
        headers: { 'Content-Type': 'application/json' }
      })

      if (response.success) {
        if (response.result.failed_orders.length > 0) {
          const errorMessages = response.result.failed_orders.map(order => (
            <div key={order.id}>
              <b>{`Заказ `}{order.id}</b>
              {`: ${order.errors.join(', ')}`}
            </div>
          ))

          Modal.error({
            title: 'Ошибки при выгрузке',
            content: <div>{errorMessages}</div>,
            onOk() {},
          })
        }

        setSelectedRowKeys([])
        getData()
      } else {
        message.error(response.error || 'Ошибка выгрузки из Шедекс', 5)
      }
    } catch (error) {
      console.error('Ошибка выгрузки:', error)
      message.error('Ошибка выгрузки из Шедекс')
    } finally {
      setLoading(false)
    }
  }, [setSelectedRowKeys, getData, setLoading, selectedRowKeys])

  const isActionButtonEnabled = useCallback((stage) => {
    return selectedRowKeys.length > 0 && selectedRowKeys.every((key) => {
      const selectedItem = data.find((item) => item.id === key)

      return selectedItem?.stage?.code === stage
    })
  }, [selectedRowKeys, data])


  const uploadProps = {
    name: 'file',
    accept: '.csv',
    beforeUpload: (file: File) => {
      handleUpload(file)
      return false
    },
    showUploadList: false
  }

  const handleCreate = () => history.push('/logistics/deliveries/new')

  const handleCancelVehicleModal = useCallback(() => {
    setIsOpenVehicleModal(false)
  }, [setIsOpenVehicleModal])

  const handleOkVehicleModal = useCallback(async (modalFormData) => {
    const response = await fetchAPI('/api/logistics/deliveries/vehicle', {
      method: 'POST',
      body: JSON.stringify({ orders: selectedRowKeys, vehicle_id: modalFormData.vehicle_id }),
      headers: { 'Content-Type': 'application/json' }
    })

    setIsOpenVehicleModal(false)
    setSelectedRowKeys([])
    setLoading(true)
    await getData()
    setLoading(false)

  }, [setIsOpenVehicleModal, setLoading, getData, selectedRowKeys])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <ActionButtons
        onCreate={handleCreate}
        uploadProps={uploadProps}
        onSendToSchedex={handleSendToSchedex}
        onUnloadFromSchedex={handleUnloadFromSchedex}
        isSendToSchedexEnabled={isActionButtonEnabled('new')}
        isUnloadFromSchedexEnabled={isActionButtonEnabled('sent_to_schedex')}
        onSetVehicle={() => setIsOpenVehicleModal(true)}
        isSetVehicleEnabled={isActionButtonEnabled('uploaded_from_schedex')}
      />
      <FiltersList
        filters={[
          {
            name: 'id',
            placeholder: 'ID',
            type: 'input-integer',
            value: currentFilters?.id,
            options: {
              width: '170px',
            },
          },
          {
            name: 'order_reference',
            placeholder: '№ ТЗ',
            type: 'input-string',
            value: currentFilters?.order_reference,
            options: {
              width: '170px',
            },
          },
          {
            name: 'client_phone',
            type: 'phone',
            value: currentFilters?.client_phone,
          },
          {
            name: 'stage',
            placeholder: 'Фильтр по стадии',
            type: 'select',
            value: currentFilters?.stage,
            options: {
              enum: availableFilters?.stage,
            },
          },
          {
            name: 'dates',
            type: 'dates',
            value: currentFilters?.dates,
          },
          {
            name: 'vehicle_id',
            placeholder: 'Машина',
            type: 'select',
            value: currentFilters?.vehicle_id,
            options: {
              enum: availableFilters?.vehicle,
            },
          },
        ]}
        onChange={changeFiltersHandler}
      />
      <Table
        showSorterTooltip={{ overlay: 'Нажмите для сортировки', placement: 'bottom' }}
        columns={getColumns(isLeader, sorter)}
        rowKey={r => r?.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        onRow={record => ({ onClick: () => handleRowClick(record) })}
        scroll={{
          x: 'max-content',
        }}
        size="small"
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange
        }}
      />
      <VehicleModal
        isModalOpen={isOpenVehicleModal}
        onCancel={handleCancelVehicleModal}
        onOk={handleOkVehicleModal}
        enums={enums}
      />
    </B2BLayout>
  )
}

export default DeliveriesList
